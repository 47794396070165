import { Box, Button, Flex, FormControl, FormLabel, Icon, InputGroup, InputLeftElement, Spinner, useColorModeValue } from '@chakra-ui/react';
import { Select as MultiSelect } from 'chakra-react-select';
import { Field, Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPerson, BsScissors } from 'react-icons/bs';
import { MdPerson } from 'react-icons/md';
import { CustomersSelectCustomComponents, TreatmentsSelectCustomComponents } from 'shared/components/SelectComponents';
import { getCommonEmployeesFromTreatments, isValidUUID, serializeCustomers, serializeTreatments } from 'shared/helpersFunctions';
import { useBusinessCustomers } from 'shared/hooks/useBusinessCustomers';
import { useTreatments } from 'shared/hooks/useTreatments';
import { AppointmentEntity, CreateAppointmentPayloadStep1, TreatmentSelectType } from 'shared/types/app.types';

interface ICreateAppointmentStep1FormProps {
  setUpStep1: (payload: CreateAppointmentPayloadStep1, chosenTreatment: TreatmentSelectType[], chosenCustomer: any) => void;
  appointmentData?: AppointmentEntity;
}

export default function CreateAppointmentStep1Form({ setUpStep1, appointmentData }: ICreateAppointmentStep1FormProps) {
  const iconColor = useColorModeValue('brand.400', 'brandScheme.200');
  const { userData } = useContext(AuthenticationContext);
  const existingCustomer = appointmentData
    ? serializeCustomers(appointmentData.customerId ? [appointmentData.customerId] : [{ freeText: appointmentData.freeText }])
    : [];
  const [chosenCustomer, setChosenCustomer] = useState<any>(existingCustomer[0] || undefined);
  const [customerInput, setCustomerInput] = useState('');
  const { t } = useTranslation();
  const { customersData, isLoadingCustomers } = useBusinessCustomers({
    businessData: userData.business,
    page: 1,
    searchTerm: customerInput,
  });
  const { treatments, isLoadingTreatments } = useTreatments();
  const existingTreatments = serializeTreatments(appointmentData?.treatments ?? []);
  const [chosenTreatments, setChosenTreatments] = useState<TreatmentSelectType[]>(existingTreatments);
  const treatmentsHasCommonEmployees = getCommonEmployeesFromTreatments(chosenTreatments).length === 0;

  const nextStepDispatcher = (values: Record<string, string>, actions: FormikHelpers<Record<string, string>>) => {
    const payload: CreateAppointmentPayloadStep1 = {
      businessId: userData.business.id,
      treatments: chosenTreatments,
    };
    if (isValidUUID(chosenCustomer?.value)) payload.customerId = chosenCustomer.value;
    else payload.freeText = chosenCustomer?.value;
    setUpStep1(payload, chosenTreatments, chosenCustomer);
  };

  const disableCreateButton = (errors: FormikErrors<Record<string, string>>) => {
    return (
      !!Object.keys(errors).length ||
      !Object.keys(chosenCustomer ?? {}).length ||
      !Object.keys(chosenTreatments ?? {}).length ||
      treatmentsHasCommonEmployees
    );
  };

  const [debouncedValue, setDebouncedValue] = useState('');
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(customerInput), 500);
    return () => clearTimeout(handler);
  }, [customerInput]);

  const mergedCustomers = useMemo(() => {
    if (!debouncedValue) return customersData.items;
    const exists = customersData.items.some((c) => c.value === debouncedValue);
    if (exists) return customersData.items;
    return [...customersData.items, { value: debouncedValue, label: debouncedValue, Icon: BsPerson, iconColor: 'brand.900' }];
  }, [customersData.items, debouncedValue]);

  return (
    <Formik initialValues={{}} onSubmit={nextStepDispatcher}>
      {({ isSubmitting, values, errors }) => (
        <Form>
          <Field name="customerIdOrFreeText">
            {({ field, form }: { field: any; form: any }) => (
              <FormControl mb="10px" isRequired isInvalid={form.errors.customerIdOrFreeText && form.touched.customerIdOrFreeText}>
                <FormLabel htmlFor="customerIdOrFreeText">
                  {t('Customer')} / {t('Free Text')}
                </FormLabel>
                <InputGroup>
                  <Box w="90%">
                    <MultiSelect
                      value={chosenCustomer}
                      components={CustomersSelectCustomComponents}
                      isClearable
                      isLoading={isLoadingCustomers}
                      options={mergedCustomers}
                      variant="auth"
                      placeholder={t('Select Customer')}
                      onInputChange={(val) => setCustomerInput(val)}
                      onChange={(opt) => setChosenCustomer(opt)}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 2000,
                        }),
                      }}
                      chakraStyles={{
                        valueContainer: (provided) => ({
                          ...provided,
                          display: 'flex',
                        }),
                      }}
                    />
                  </Box>
                  <InputLeftElement display="flex" alignItems="center" mt="4px">
                    <Icon color={iconColor} as={MdPerson} />
                  </InputLeftElement>
                </InputGroup>
              </FormControl>
            )}
          </Field>
          <Field name="treatments">
            {({ field, form }: { field: any; form: any }) => (
              <FormControl mb="20px" isInvalid={form.errors.treatments && form.touched.treatments} isRequired>
                <FormLabel htmlFor="treatments">{t('Treatment')}</FormLabel>
                <InputGroup>
                  <Box w="90%">
                    {!isLoadingTreatments ? (
                      <MultiSelect
                        isMulti
                        value={chosenTreatments}
                        components={TreatmentsSelectCustomComponents}
                        options={treatments}
                        variant="auth"
                        placeholder={t('Select treatment')}
                        onChange={(treatments) => {
                          setChosenTreatments([...treatments]);
                        }}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 2000,
                          }),
                        }}
                        chakraStyles={{
                          valueContainer: (provided) => ({
                            ...provided,
                            display: 'flex',
                          }),
                        }}
                      />
                    ) : (
                      <Spinner />
                    )}
                  </Box>
                  <InputLeftElement display="flex" alignItems="center" mt="4px">
                    <Icon color={iconColor} as={BsScissors} />
                  </InputLeftElement>
                </InputGroup>
                <Box sx={{ color: 'red.400' }}>
                  {treatmentsHasCommonEmployees && chosenTreatments.length > 0 && <>{t('No employee can give all those treatments')}</>}
                </Box>
              </FormControl>
            )}
          </Field>
          <Flex pt="10px" gap="5px" justifyContent={'end'}>
            <Button variant="brand" fontSize="md" type="submit" fontWeight="500" isDisabled={disableCreateButton(errors)} px="24px" py="5px">
              {t('Next')}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
