/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Flex, SimpleGrid, Spinner, useColorModeValue } from '@chakra-ui/react';
// Assets
// Custom components
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getDashboardBasicStats, getDashboardTreatmentsRevenue } from 'services/api/business';
import { ACTIVE, ADMIN } from 'shared/consts';
import { getPastMonths } from 'shared/helpersFunctions';
import { DashboardData } from 'shared/types/app.types';
import PieCardTreatment from 'views/admin/dashboard/components/PieCardTreatment';
import AppointmentsPerMonth from 'views/admin/dashboard/components/TotalSpent';
import TreatmentsRevenue from 'views/admin/dashboard/components/WeeklyRevenue';
import PieCardEmployee from './components/PieCardEmployee';
import PieCardEmployeeAbsences from './components/PieCardEmployeeAbsences';

export default function BusinessInsights() {
  const brandColor = useColorModeValue('brand.100', 'white');
  const { t } = useTranslation();
  const { userData } = useContext(AuthenticationContext);
  const { data: dashboardBasicData } = useQuery<DashboardData>(['dashboard-basic-stats'], () => getDashboardBasicStats(userData.business.id), {
    refetchOnMount: 'always',
    enabled: userData.user.role.name === ADMIN,
  });

  const { data: treatmentsRevenueData, isLoading: isLoadingTreatmentsData } = useQuery<any>(
    ['dashboard-treatments-revenue'],
    () => getDashboardTreatmentsRevenue(userData.business.id, getPastMonths()),
    {
      enabled: userData.user.status.name === ACTIVE && userData.user.role.name === ADMIN,
      refetchOnMount: 'always',
    },
  );

  if (!dashboardBasicData) {
    return (
      <Flex w="80vw" h="100vh" alignItems="center" justifyContent={'center'}>
        <Spinner />
      </Flex>
    );
  }
  // Chakra Color Mode
  return (
    <Box pt={{ base: '120px', md: '100px', xl: '100px' }} px={{ base: '20px', md: '10px' }}>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <AppointmentsPerMonth businessId={userData.business.id} />
        {treatmentsRevenueData && !isLoadingTreatmentsData && <TreatmentsRevenue treatmentsRevenueData={treatmentsRevenueData} />}
        {isLoadingTreatmentsData && (
          <Box>
            <Spinner />
          </Box>
        )}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap="20px" mb="20px">
        <PieCardTreatment businessId={userData.business.id} title={t('Appointments Per Treatment')} />
        <PieCardEmployee businessId={userData.business.id} title={t('Appointments Per Employee')} />
        <PieCardEmployeeAbsences businessId={userData.business.id} title={t('Absences Per Employee')} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        {/* <ComplexTable tableData={tableDataComplex} />
        <CheckTable tableData={tableDataCheck} /> */}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        {/* <Tasks /> */}
        {/* <DailyTraffic /> */}
      </SimpleGrid>
    </Box>
  );
}
