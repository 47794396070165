import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';

interface GenericSelectProps<T> {
  label: string;
  value: T;
  onChange: (value: T) => void;
  options: Array<{ value: string; label: string }>;
  [key: string]: any;
}

export default function Select<T>({ label, value, onChange, options, ...props }: GenericSelectProps<T>) {
  const handleChange = (event: SelectChangeEvent<T>) => {
    onChange(event.target.value as T);
  };

  return (
    <FormControl sx={{ my: 1, minWidth: 80 }} fullWidth>
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <MuiSelect
        labelId={`${label}-label`}
        id={`${label}-select`}
        value={value}
        onChange={handleChange}
        label={label}
        sx={{ height: '40px' }} // Change the height of the input
        MenuProps={{
          sx: { zIndex: 2000 },
        }}
        {...props}
      >
        {options.map((option) => (
          <MenuItem key={option.value as string} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}
