import { Icon } from '@chakra-ui/react';
import { BsBrush, BsPeople } from 'react-icons/bs';
import { FaUsers } from 'react-icons/fa';
import { FcStatistics } from 'react-icons/fc';
import { IoMdPaper } from 'react-icons/io';
import { MdBusiness, MdCalendarToday, MdHome, MdNotifications, MdPerson } from 'react-icons/md';
import { ADMIN } from 'shared/consts';

// Admin Imports
import Calendar from 'views/admin/calendar';
import MainDashboard from 'views/admin/dashboard';
import HomePage from 'views/admin/homePage';
import BusinessSettings from 'views/admin/settings/business';
import EmployeesSettings from 'views/admin/settings/employees';
import Profile from 'views/admin/settings/profile';
import TreatmentsSettings from 'views/admin/settings/treatments';

// Auth Imports

const mainRoutes = [
  {
    name: 'Home Page',
    layout: '/admin',
    path: '/admin',
    icon: <Icon as={MdHome} display="flex" width="20px" height="20px" color="inherit" />,
    plans: ['*'],
    roles: [ADMIN],
    component: HomePage,
  },
  {
    name: 'Business Insights',
    layout: '/admin',
    path: '/admin/business-insights',
    icon: <Icon as={FcStatistics} display="flex" width="20px" height="20px" color="inherit" />,
    plans: ['*'],
    roles: [ADMIN],
    component: MainDashboard,
  },
  {
    name: 'Calendar',
    layout: '/admin',
    icon: <Icon as={MdCalendarToday} display="flex" width="20px" height="20px" color="inherit" />,
    path: '/admin/calendar',
    plans: ['*'],
    roles: ['*'],
    component: Calendar,
  },
  {
    name: 'Notifications',
    layout: '/notifications',
    icon: <Icon as={MdNotifications} display="flex" width="20px" height="20px" color="inherit" />,
    path: '/admin/notifications',
    plans: ['*'],
    roles: ['*'],
    component: Calendar,
  },
  {
    name: 'Reports',
    layout: '/reports',
    icon: <Icon as={IoMdPaper} display="flex" width="20px" height="20px" color="inherit" />,
    path: '/admin/reports',
    plans: ['Pro', 'Premium'],
    roles: [ADMIN],
    component: Calendar,
  },
  {
    name: 'Customers',
    layout: '/customers',
    icon: <Icon as={BsPeople} display="flex" width="20px" height="20px" color="inherit" />,
    path: '/admin/customers',
    plans: ['Pro', 'Premium'],
    roles: [ADMIN],
    component: Calendar,
  },
];

export const settingsRoutes = [
  {
    name: 'Business',
    layout: '',
    path: '/admin/settings/business',
    icon: <Icon as={MdBusiness} display="flex" width="20px" height="20px" color="inherit" />,
    plans: ['*'],
    roles: ['*'],
    component: BusinessSettings,
    secondary: true,
  },
  {
    name: 'Employees',
    layout: '',
    path: '/admin/settings/employees',
    icon: <Icon as={BsPeople} display="flex" width="20px" height="20px" color="inherit" />,
    plans: ['*'],
    roles: ['*'],
    component: EmployeesSettings,
    secondary: true,
  },
  {
    name: 'Treatments',
    layout: '',
    path: '/admin/settings/treatments',
    icon: <Icon as={BsBrush} display="flex" width="20px" height="20px" color="inherit" />,
    plans: ['*'],
    roles: ['*'],
    component: TreatmentsSettings,
    secondary: true,
  },
  {
    name: 'Users',
    layout: '',
    path: '/admin/settings/users',
    icon: <Icon as={FaUsers} display="flex" width="20px" height="20px" color="inherit" />,
    plans: ['Pro', 'Premium'],
    roles: [ADMIN],
    component: TreatmentsSettings,
    secondary: true,
  },
  {
    name: 'Profile',
    layout: '',
    path: '/admin/settings/profile',
    icon: <Icon as={MdPerson} display="flex" width="20px" height="20px" color="inherit" />,
    plans: ['*'],
    roles: ['*'],
    component: Profile,
  },
];

export default mainRoutes;
