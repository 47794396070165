import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import defaultLogo from 'assets/img/business/business-logo.png';
import phoneApple from 'assets/img/icons/phone-apple.png';

import 'add-to-calendar-button';
import FemaleEmployeeImage from 'assets/img/business/female-employee.jpeg';
import MaleEmployeeImage from 'assets/img/business/man-employee.jpeg';
import whatsApp from 'assets/img/icons/whatsapp.png';
import IconBox from 'components/icons/IconBox';
import { CalendarContext } from 'providers/CalendarProvider';
import { useContext, useRef, useState } from 'react';
import { BsCalendar, BsPerson, BsScissors, BsTrash } from 'react-icons/bs';
import { FaBirthdayCake, FaChevronLeft, FaComment } from 'react-icons/fa';

import { MdComment, MdEdit, MdWork } from 'react-icons/md';

import { EventImpl } from '@fullcalendar/core/internal';
import { PhoneIcon } from 'components/icons/Icons';
import Select from 'components/inputs/Select';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { AppointmentDeletionReasonEnum } from 'shared/enums';
import { displayDateReverse, getCustomerBirthdayText, getDayName, getMinutesDifference } from 'shared/helpersFunctions';
import { AppointmentEntity, TreatmentEntityType } from 'shared/types/app.types';

interface IAppointmentDrawerProps {
  isOpen: boolean;
  onEditClick: (appointmentData: AppointmentEntity) => void;
  onEditOrDeleteDrawerClose: () => void;
  closeEditOrDeleteModal: () => void;
  appointmentData: AppointmentEntity;
  removeEventCallback?: EventImpl;
}

export default function AppointmentDrawer({
  isOpen,
  onEditClick,
  onEditOrDeleteDrawerClose,
  closeEditOrDeleteModal,
  appointmentData,
  removeEventCallback,
}: IAppointmentDrawerProps) {
  const { isOpen: isOpenDeleteModal, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [deletionReason, setDeletionReason] = useState<AppointmentDeletionReasonEnum>(AppointmentDeletionReasonEnum.SERVICE_NOT_REQUIRED);
  const headingColor = useColorModeValue('navy.400', 'navy.100');
  const { deleteAppointmentMutation } = useContext(CalendarContext);
  const deletionReasonsArray = Object.values(AppointmentDeletionReasonEnum).map((reason) => ({ value: reason, label: t(reason) }));
  const { userData } = useContext(AuthenticationContext);
  const cancelRef = useRef();
  const errorColorBrand = useColorModeValue('red.500', 'red.200');
  const iconColor = useColorModeValue('brand.600', 'brand.300');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.400');
  const brandColor = useColorModeValue('brand.600', 'brand.100');
  const initialRef = useRef();
  const finalRef = useRef();

  const {
    customerId: customer,
    employeeId: employee,
    treatments,
    freeText,
    price,
    startOfAppointment,
    endOfAppointment,
    dateOfAppointment,
    comment,
    absence,
  } = appointmentData;

  const deleteAppointmentDispatcher = () => {
    deleteAppointmentMutation({
      appointmentId: appointmentData.id,
      deletionReason,
      onClose: () => {
        onClose();
        onEditOrDeleteDrawerClose();
        closeEditOrDeleteModal();
        if (removeEventCallback) {
          removeEventCallback.remove();
        }
      },
    });
  };

  const handleReasonChangeChange = (value: AppointmentDeletionReasonEnum) => {
    // Handle the reason change logic here
    setDeletionReason(value);
  };

  const sendWhatsAppMessage = () => {
    const phoneNumber = `+972${customer?.phone}`;
    const message = encodeURIComponent('I wo');
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.location.href = whatsappUrl;
  };

  const callCustomer = () => {
    return (window.location.href = `tel:${customer?.phone}`);
  };

  const smsCustomer = () => {
    return (window.location.href = `sms:${customer?.phone}`);
  };

  const getEmployeeImage = () => {
    if (employee.photo) {
      return employee.photo.path;
    }
    return employee.gender === 'M' ? MaleEmployeeImage : FemaleEmployeeImage;
  };
  const appointmentDate = new Date(dateOfAppointment);
  const customerBirthday = getCustomerBirthdayText(customer);

  return (
    <Drawer initialFocusRef={initialRef} finalFocusRef={finalRef} size="lg" isOpen={isOpen} onClose={closeEditOrDeleteModal}>
      <DrawerOverlay />
      <DrawerContent sx={{ zIndex: 30000 }}>
        <Box sx={{ position: 'relative' }}>
          <Box
            onClick={closeEditOrDeleteModal}
            sx={{
              position: 'absolute',
              top: window.innerHeight / 2,
              left: -3,
              zIndex: 30000,
              transform: 'translateY(-50%)',
              p: 3,
              borderRadius: '50%',
              backgroundColor: 'white',
              boxShadow: '0px 0px 120px 1px black',
            }}
          >
            <Icon as={FaChevronLeft} display="flex" width="20px" height="20px" color={brandColor} />
          </Box>
        </Box>

        <DrawerCloseButton size={'large'} />
        <DrawerHeader px={16}>
          <Flex gap="5px" alignItems={'center'} justifyContent="space-between">
            <Heading size="md">{absence ? t('Absence Details') : t('Appointment Details')}</Heading>
          </Flex>
        </DrawerHeader>
        <DrawerBody pb={6}>
          <Card>
            <CardBody textAlign={'center'}>
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Flex justifyContent="center" alignItems={'center'} gap={2}>
                    <IconBox
                      w="36px"
                      h="36px"
                      bg={boxBg}
                      icon={<Icon as={BsCalendar} display="flex" color={brandColor} width="20px" height="20px" />}
                    />
                    <Heading size="md" color={headingColor} fontWeight={'700'} textTransform="uppercase">
                      {t('Date & Time')}
                    </Heading>
                  </Flex>
                  <Flex pt="2" justifyContent={'center'} flexDirection="column" alignItems="center" gap={2}>
                    <Flex gap={2}>
                      <Text fontSize="lg" fontWeight={800}>
                        {t('Day')} {t(getDayName(appointmentDate))}
                      </Text>
                      <Text fontSize="lg" fontWeight={800}>
                        {displayDateReverse(dateOfAppointment)}
                      </Text>
                    </Flex>
                    <Flex gap={2}>
                      <Text fontSize="lg">
                        {startOfAppointment.substr(0, 5)} - {endOfAppointment.substr(0, 5)}
                      </Text>
                      <Text>{`(${getMinutesDifference(startOfAppointment, endOfAppointment)} ${t('Minutes')})`}</Text>
                    </Flex>
                    {/* <add-to-calendar-button
                      name={`${customer ? `${customer.firstName} ${customer.lastName}` : freeText}: ${treatments.map((treat) => treat.name).join(', ')}`}
                      description={`${t('Comment')}:${comment},\n ${customer ? `${customer.firstName} ${customer.lastName}` : freeText}`}
                      startDate={getCurrentDate(dateOfAppointment)}
                      endDate={getCurrentDate(dateOfAppointment)}
                      startTime={startOfAppointment}
                      endTime={endOfAppointment}
                      status="CONFIRMED"
                      options="['Apple','Google','Microsoft365']"
                      timeZone="currentBrowser"
                      trigger="click"
                      inline
                      buttonStyle="default"
                      size="3|3|3"
                      label={t('Add To Calendar')}
                      listStyle="modal"
                      iCalFileName="Reminder-Event"
                    /> */}
                  </Flex>
                </Box>
                {!absence && (
                  <Box>
                    <Flex justifyContent="center" alignItems={'center'} gap={2}>
                      <IconBox
                        w="36px"
                        h="36px"
                        bg={boxBg}
                        icon={<Icon as={BsPerson} display="flex" color={brandColor} width="20px" height="20px" />}
                      />
                      <Heading size="md" color={headingColor} fontWeight={'700'} textTransform="uppercase">
                        {t('Customer')}
                      </Heading>
                    </Flex>
                    <Flex pt="2" justifyContent={'center'} alignItems="center" gap={2}>
                      {customer?.photo && <Avatar h="35px" w="35px" fontSize={'3px'} src={customer?.photo.path} />}
                      <Text fontSize="lg" fontWeight={800}>
                        {customer ? `${customer.firstName} ${customer.lastName}` : freeText}
                      </Text>
                      <Text fontSize="md">{customer?.phone}</Text>
                    </Flex>
                    {customerBirthday && (
                      <Flex pt="2" justifyContent={'center'} alignItems="center" gap={2}>
                        <Text fontSize="medium" fontWeight={600}>
                          {customerBirthday}
                        </Text>
                        <Icon as={FaBirthdayCake} color="orange.600" width="30px" height="30px" />
                      </Flex>
                    )}
                    <Flex pt="2" justifyContent={'center'} alignItems="center" gap={2}>
                      {appointmentData.fromWaitingList && `(${t('Created from waiting list')})`}
                    </Flex>
                  </Box>
                )}
                {!absence && (
                  <Box>
                    <Flex justifyContent="center" alignItems={'center'} gap={2}>
                      <IconBox
                        w="36px"
                        h="36px"
                        bg={boxBg}
                        icon={<Icon as={BsScissors} display="flex" color={brandColor} width="20px" height="20px" />}
                      />
                      <Heading size="md" color={headingColor} fontWeight={'700'} textTransform="uppercase">
                        {t('Treatment')}
                      </Heading>
                    </Flex>
                    <Flex pt="2" flexDirection="column" justifyContent={'center'} alignItems="center">
                      <Box sx={{ borderBottom: `1px solid white` }}>
                        {treatments.map((treat: TreatmentEntityType) => (
                          <Flex gap={2} key={treat.name}>
                            <Text fontWeight="500" textOverflow={'ellipsis'}>{` ${treat.name} `}</Text>
                            <Text fontWeight="500" textOverflow={'ellipsis'}>{` ${treat.price} ₪ `}</Text>
                          </Flex>
                        ))}
                      </Box>
                      <Divider />
                      <Text fontWeight="700" textOverflow={'ellipsis'}>
                        {`${t('Total')} ${price} ₪`}
                      </Text>
                    </Flex>
                  </Box>
                )}
                <Box>
                  <Flex justifyContent="center" alignItems={'center'} gap={2}>
                    <IconBox w="36px" h="36px" bg={boxBg} icon={<Icon as={MdWork} display="flex" color={brandColor} width="20px" height="20px" />} />
                    <Heading size="md" color={headingColor} fontWeight={'700'} textTransform="uppercase">
                      {absence ? t('Employee Absence') : t('Employee')}
                    </Heading>
                  </Flex>
                  <Flex pt="2" justifyContent={'center'} alignItems="center" gap={2}>
                    <Avatar h="35px" w="35px" fontSize={'3px'} src={getEmployeeImage()} />
                    <Text fontSize="lg">{`${employee.firstName} ${employee.lastName}`}</Text>
                  </Flex>
                </Box>
                <Box>
                  <Flex justifyContent="center" alignItems={'center'} gap={2}>
                    <IconBox
                      w="36px"
                      h="36px"
                      bg={boxBg}
                      icon={<Icon as={MdComment} display="flex" color={brandColor} width="20px" height="20px" />}
                    />
                    <Heading size="md" color={headingColor} fontWeight={'700'} textTransform="uppercase" textDecoration={!comment && 'line-through'}>
                      {t('Comment')}
                    </Heading>
                  </Flex>
                  <Flex pt="2" justifyContent={'center'} alignItems="center" gap={2}>
                    <Text fontSize="lg">{comment}</Text>
                  </Flex>
                </Box>
              </Stack>
            </CardBody>
          </Card>
          <Flex alignItems={'center'} justifyContent={'center'} gap={2} my={2}>
            {customer && (
              <>
                <Avatar
                  src={whatsApp}
                  borderRadius={'10px'}
                  onClick={sendWhatsAppMessage}
                  size={{ base: 'xl', md: 'xl', lg: 'xl', '3xl': '2xl' }}
                  h={{ base: '55px', md: '55px', lg: '55px', '3xl': '57px' }}
                  w={{ base: '55px', md: '55px', lg: '55px', '3xl': '57px' }}
                  sx={{ cursor: 'pointer' }}
                />
                <Avatar
                  src={phoneApple}
                  onClick={callCustomer}
                  borderRadius={'10px'}
                  size={{ base: 'xl', md: 'xl', lg: 'xl', '3xl': '2xl' }}
                  h={{ base: '55px', md: '55px', lg: '55px', '3xl': '57px' }}
                  w={{ base: '55px', md: '55px', lg: '55px', '3xl': '57px' }}
                  sx={{ cursor: 'pointer' }}
                />
                <FaComment
                  size={50}
                  onClick={smsCustomer}
                  fill="white"
                  style={{
                    backgroundColor: '#1fca3b',
                    padding: 4,
                    borderRadius: 6,
                    cursor: 'pointer',
                  }}
                />
              </>
            )}
          </Flex>
          {!appointmentData.createdByCustomer ? (
            <Flex alignItems={'center'} justifyContent={'center'} gap={2} my={2}>
              <Text fontSize="sm">{t('Created By')}</Text>
              <Avatar
                src={userData.business?.businessLogo?.path ?? defaultLogo}
                size={{ base: 'xl', md: 'xl', lg: 'xl', '3xl': '2xl' }}
                h={{ base: '55px', md: '55x', lg: '60px', '3xl': '60px' }}
                w={{ base: '55px', md: '55x', lg: '60px', '3xl': '60px' }}
              />
            </Flex>
          ) : (
            <Flex alignItems={'center'} justifyContent={'center'} gap={2} my={2}>
              <Text fontSize="sm">{t('Created by customer')}</Text>
              <Avatar
                icon={<PhoneIcon color={iconColor} h="34px" w="34px" />}
                size={{ base: 'lg', md: 'lg', lg: 'lg', '3xl': 'xl' }}
                h={{ base: '40px', md: '40px', lg: '45px', '3xl': '45px' }}
                w={{ base: '40px', md: '40px', lg: '45px', '3xl': '45px' }}
              />
            </Flex>
          )}
        </DrawerBody>
        <DrawerFooter>
          <Flex pt="10px" gap="5px" w="100%" justifyContent={'space-between'}>
            <Button
              color={'white'}
              bg={errorColorBrand}
              fontSize="md"
              fontWeight="500"
              leftIcon={<Icon as={BsTrash} h="18px" w="18px" />}
              px="24px"
              onClick={onOpen}
              py="5px"
            >
              {t('Delete')}
            </Button>
            <Button
              variant="brand"
              fontSize="md"
              fontWeight="500"
              onClick={() => onEditClick(appointmentData)}
              sx={{ alignItems: 'center' }}
              leftIcon={<Icon as={MdEdit} h="18px" w="18px" />}
              px="24px"
              py="5px"
            >
              {t('Edit')}
            </Button>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
      <AlertDialog isOpen={isOpenDeleteModal} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
              {t('Delete Appointment?')}
            </AlertDialogHeader>

            <AlertDialogBody textAlign="center">
              <Select label={t('Choose a reason')} value={deletionReason} onChange={handleReasonChangeChange} options={deletionReasonsArray} />
              <Text>{t("Are you sure? You can't undo this action afterwards.")}</Text>
            </AlertDialogBody>

            <AlertDialogFooter gap={2} display="flex" justifyContent="center">
              <Button ref={cancelRef} onClick={onClose}>
                {t('Cancel')}
              </Button>
              <Button colorScheme="red" onClick={deleteAppointmentDispatcher} ml={3}>
                {t('Delete')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Drawer>
  );
}
