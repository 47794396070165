import { EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import MiniCalendar from 'components/calendar/MiniCalendar';
import CreateAbsenceStep1Form from 'components/forms/createAbsense/CreateAsenceStep1Form';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCalendar, BsPerson } from 'react-icons/bs';
import { MdOutlineRemoveCircle, MdPlusOne, MdTimer } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import { createAbsence, updateAbsence } from 'services/api/appointments';
import { DEFAULT_END_TIME, DEFAULT_START_TIME } from 'shared/consts';
import { getDayName } from 'shared/helpersFunctions';
import { AppointmentEntity } from 'shared/types/app.types';

interface IAppointmentTypeModalProps {
  isOpen: boolean;
  isAbsenceOpen: boolean;
  appointmentData?: AppointmentEntity;
  businessWorkingDaysWeekNumbers: number[];
  initialDate: Date;
  maxCloseTime: string;
  minOpenTime: string;
  onAbsenceClose: () => void;
  onAbsenceOpen: () => void;
  closeModal: () => void;
  onCreateOpen: () => void;
}

export default function AppointmentTypeModal({
  isOpen,
  isAbsenceOpen,
  appointmentData,
  businessWorkingDaysWeekNumbers,
  maxCloseTime,
  minOpenTime,
  initialDate,
  closeModal,
  onCreateOpen,
  onAbsenceClose,
  onAbsenceOpen,
}: IAppointmentTypeModalProps) {
  const newAppointmentColor = useColorModeValue('brand.400', 'brand.500');
  const stepsColorBg = useColorModeValue('orange.300', 'orange.500');

  const [selectedDate, setSelectedDate] = useState<Date>(initialDate);
  const isValidBusinessDay = businessWorkingDaysWeekNumbers.includes(selectedDate.getDay());
  const { userData } = useContext(AuthenticationContext);
  const [chosenEmployee, setChosenEmployee] = useState<any>();
  const [comment, setComment] = useState<string>(appointmentData?.comment ?? '');
  const [fromTime, setFromTime] = useState<string>(appointmentData?.startOfAppointment.substring(0, 5) ?? DEFAULT_START_TIME);
  const [untilTime, setUntilTime] = useState<string>(appointmentData?.endOfAppointment.substring(0, 5) ?? DEFAULT_END_TIME);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorBusinessTimes, setErrorBusinessTimes] = useState<boolean>(false);
  const absenceColor = useColorModeValue('red.400', 'red.500');
  const { t } = useTranslation();

  const { isOpen: isDateModalOpen, onOpen: onDateModalOpen, onClose: onDateModalClose } = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  const { mutate: createAbsenceMutation, isLoading: isLoadingAbsenceCreation } = useMutation(createAbsence, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('calendar');
      onAbsenceClose();
      toast({
        title: t('Absence Created Successfully'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('Ops error occurred try again later'),
        description: t('Maybe employee is already busy in those times'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const { mutate: updateAbsenceMutation, isLoading: isLoadingAbsenceUpdate } = useMutation(updateAbsence, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('calendar');
      onAbsenceClose();
      toast({
        title: t('Absence Update Successfully'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('Ops error occurred try again later'),
        description: t('Maybe employee is already busy in those times'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleCreateOrEditAbsence = () => {
    if (!isValidBusinessDay || errorBusinessTimes) {
      return;
    }
    if (appointmentData) {
      updateAbsenceMutation({
        id: appointmentData.id,
        payload: {
          comment,
          employeeId: chosenEmployee.employeeId,
          startOfAppointment: fromTime,
          dateOfAppointment: selectedDate.toISOString().split('T')[0],
          endOfAppointment: untilTime,
          businessId: userData.business.id,
        },
      });
    } else {
      createAbsenceMutation({
        comment,
        employeeId: chosenEmployee.employeeId,
        startOfAppointment: fromTime,
        dateOfAppointment: selectedDate.toISOString().split('T')[0],
        endOfAppointment: untilTime,
        businessId: userData.business.id,
      });
    }
  };

  const initialRef = useRef();
  const finalRef = useRef();
  const initialRef2 = useRef();
  const finalRef2 = useRef();
  const handleClickNewAppointment = () => {
    closeModal();
    onCreateOpen();
  };

  const timeStringToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const onDoneOrExit = () => {
    onAbsenceClose();
    reset();
  };

  const setUpStep2 = (payloadStep2: any) => {
    setChosenEmployee(payloadStep2);
    nextStep();
  };

  const handleClickNewAbsence = () => {
    onAbsenceOpen();
    setFromTime(DEFAULT_START_TIME);
    setUntilTime(DEFAULT_END_TIME);
    setIsError(false);
    setChosenEmployee(undefined);
    closeModal();
  };

  return (
    <Box>
      <Modal size="lg" initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} scrollBehavior={'outside'} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('Choose an Option')}</ModalHeader>
          <ModalBody pb={120}>
            <Flex direction="column" gap={32}>
              <Box style={{ perspective: '1000px' }}>
                <Box
                  position="relative"
                  width="100%"
                  height="100%"
                  onClick={handleClickNewAbsence}
                  transition="transform 0.5s"
                  transform="rotateY(0)"
                  _hover={{ transform: 'rotateY(180deg)' }}
                  style={{
                    transformStyle: 'preserve-3d',
                  }}
                >
                  {/* Front side */}
                  <Box
                    bg={absenceColor}
                    color="white"
                    rounded="lg"
                    shadow="lg"
                    width="100%"
                    height="100%"
                    fontSize={'18px'}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    style={{
                      backfaceVisibility: 'hidden',
                      transform: 'rotateY(0deg)',
                    }}
                    py={12}
                    px={6}
                  >
                    {t('Absence?')}
                    <Icon as={MdOutlineRemoveCircle} width="30px" height="30px" />
                  </Box>

                  {/* Back side */}
                  <Box
                    bg={absenceColor}
                    color="white"
                    rounded="lg"
                    shadow="lg"
                    width="100%"
                    height="100%"
                    fontSize={'18px'}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    style={{
                      backfaceVisibility: 'hidden',
                      transform: 'rotateY(180deg)',
                    }}
                    py={12}
                    px={6}
                  >
                    {t('Add a absence to one of the employees')}
                  </Box>
                </Box>
              </Box>

              <Box style={{ perspective: '1000px' }}>
                <Box
                  position="relative"
                  width="100%"
                  height="100%"
                  transition="transform 0.5s"
                  onClick={handleClickNewAppointment}
                  transform="rotateY(0)"
                  _hover={{ transform: 'rotateY(180deg)' }}
                  style={{
                    transformStyle: 'preserve-3d',
                  }}
                >
                  {/* Front side */}
                  <Box
                    bg={newAppointmentColor}
                    color="white"
                    rounded="lg"
                    shadow="lg"
                    width="100%"
                    height="100%"
                    display="flex"
                    fontSize={'18px'}
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    style={{
                      backfaceVisibility: 'hidden',
                      transform: 'rotateY(0deg)',
                    }}
                    py={12}
                    px={6}
                  >
                    {t('New Appointment?')}
                    <Icon as={MdPlusOne} width="30px" height="30px" />
                  </Box>

                  {/* Back side */}
                  <Box
                    bg={newAppointmentColor}
                    color="white"
                    rounded="lg"
                    shadow="lg"
                    width="100%"
                    height="100%"
                    fontSize={'18px'}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    style={{
                      backfaceVisibility: 'hidden',
                      transform: 'rotateY(180deg)',
                    }}
                    py={12}
                    px={6}
                  >
                    {t('Create a new appointment with customer, treatment etc...')}
                  </Box>
                </Box>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        size="md"
        initialFocusRef={initialRef2}
        finalFocusRef={finalRef2}
        isOpen={isAbsenceOpen}
        scrollBehavior={'outside'}
        onClose={onDoneOrExit}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('Create Absence')}</ModalHeader>
          <ModalBody pb={120}>
            <Steps
              variant={'circles'}
              colorScheme={'orange'}
              orientation="vertical"
              sx={{
                '& .cui-steps__vertical-step:not(:last-child):after': {
                  bg: stepsColorBg,
                },
              }}
              activeStep={activeStep}
            >
              <Step
                label={
                  <Flex alignItems={'center'} gap={2}>
                    <Text>{t('Employee')}</Text>
                    <Flex>
                      <Icon as={BsPerson} display="flex" width="20px" height="20px" />
                    </Flex>
                  </Flex>
                }
                key={1}
              >
                <CreateAbsenceStep1Form appointmentData={appointmentData} prevStep={prevStep} setUpStep2={setUpStep2} />
              </Step>
              <Step
                label={
                  <Flex alignItems={'center'} gap={2}>
                    <Text>{t('Date & Time')}</Text>
                    <Icon as={BsCalendar} display="flex" width="20px" height="20px" />
                    <Icon as={MdTimer} display="flex" width="20px" height="20px" />
                  </Flex>
                }
                key={2}
              >
                <Flex mb={2} alignItems={'center'} flexDirection={'column'} justifyContent="space-between" gap={2}>
                  <Flex gap={2} justifyContent="space-around" alignItems={'center'} w="90%">
                    <Flex gap={2}>
                      <Text fontSize={'18px'} fontWeight="600">
                        {selectedDate.toLocaleDateString('en-GB')}
                      </Text>
                      <Text fontSize={'18px'} fontWeight="600">
                        {t('Day')} {t(getDayName(selectedDate))}
                      </Text>
                    </Flex>
                    <Button
                      variant="action"
                      fontSize="md"
                      fontWeight="500"
                      onClick={() => onDateModalOpen()}
                      sx={{ alignItems: 'center' }}
                      rightIcon={<EditIcon w="20px" h="20px" />}
                    >
                      {t('Change')}
                    </Button>
                  </Flex>
                  <Box my={3}>
                    <HStack spacing={2}>
                      {/* From Time */}
                      <FormControl
                        id="from-time"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FormLabel sx={{ display: 'contents' }}>{t('From')}:</FormLabel>
                        <Input
                          type="time"
                          variant={'auth'}
                          value={fromTime}
                          onChange={(e) => {
                            setErrorBusinessTimes(e.target.value < minOpenTime || e.target.value > maxCloseTime);
                            setFromTime(() => e.target.value);
                            setIsError(timeStringToMinutes(e.target.value) >= timeStringToMinutes(untilTime));
                          }}
                          isInvalid={errorBusinessTimes}
                        />
                      </FormControl>
                      -{/* To Time */}
                      <FormControl
                        id="to-time"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FormLabel sx={{ display: 'contents' }}>{t('Until')}:</FormLabel>
                        <Input
                          type="time"
                          variant={'auth'}
                          value={untilTime}
                          onChange={(e) => {
                            setErrorBusinessTimes(e.target.value < minOpenTime || e.target.value > maxCloseTime);
                            setUntilTime(() => e.target.value);
                            setIsError(timeStringToMinutes(fromTime) >= timeStringToMinutes(e.target.value));
                          }}
                          isInvalid={errorBusinessTimes}
                        />
                      </FormControl>
                    </HStack>
                    {isError && (
                      <Text color="red.500" mt={2}>
                        {t('Error, times must be valid')}
                      </Text>
                    )}
                    {errorBusinessTimes && (
                      <Text color="red.500" mt={2}>
                        {`${t('Invalid business hours')} (${minOpenTime}-${maxCloseTime})`}
                      </Text>
                    )}
                    <Box mt={2}>
                      <FormLabel htmlFor="comment">{t('Comment')}</FormLabel>
                      <Textarea
                        value={comment}
                        variant={'auth'}
                        onChange={(element) => setComment(element.target.value)}
                        placeholder={t('Add your comment here')}
                        size="lg"
                      />
                    </Box>
                  </Box>
                  <Flex pt="10px" gap={2} justifyContent={'end'} w="100%">
                    <Button variant="action" fontSize="md" fontWeight="500" px="24px" onClick={prevStep} py="5px">
                      {t('Back')}
                    </Button>
                    <Button
                      variant="brand"
                      fontSize="md"
                      type="submit"
                      fontWeight="600"
                      onClick={handleCreateOrEditAbsence}
                      isLoading={isLoadingAbsenceCreation || isLoadingAbsenceUpdate}
                      isDisabled={isError}
                      px="24px"
                      py="5px"
                    >
                      {appointmentData ? t('Update') : t('Create')}
                    </Button>
                  </Flex>
                </Flex>
                <Modal
                  size="xl"
                  initialFocusRef={initialRef}
                  finalFocusRef={finalRef}
                  isOpen={isDateModalOpen}
                  scrollBehavior={'outside'}
                  onClose={onDateModalClose}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>{t('Select your date')}</ModalHeader>
                    <Box p={10}>
                      <MiniCalendar
                        initValue={selectedDate}
                        h="100%"
                        minW="100%"
                        selectRange={false}
                        onChange={(value) => {
                          setSelectedDate(value);
                          onDateModalClose();
                        }}
                      />
                    </Box>
                  </ModalContent>
                </Modal>
              </Step>
            </Steps>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
