import { Avatar, Box, Button, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import MiniCard from 'components/cards/MiniCard';
import IconBox from 'components/icons/IconBox';
import { useTranslation } from 'react-i18next';
import { BsCalendar, BsClock, BsScissors } from 'react-icons/bs';
import { MdComment } from 'react-icons/md';
import { DAYS_OF_WEEK } from 'shared/consts';
import { AppointmentEntity, CreateAppointmentPayloadStep1, CreateAppointmentPayloadStep2, EmployeeSelectType } from 'shared/types/app.types';

interface ICreateAppointmentStep5SummaryProps {
  prevStep: () => void;
  createAppointmentDispatcher: () => void;
  appointmentData?: AppointmentEntity;
  comment: string;
  employeeToDisplay: EmployeeSelectType;
  payloadStep1: CreateAppointmentPayloadStep1;
  payloadStep2: CreateAppointmentPayloadStep2;
  customerOrFreeTextToDisplay: any;
  selectedDate: Date;
  startOfAppointment: string;
  endOfAppointment: string;
  loading: boolean;
}
export default function CreateAppointmentStep5Summary({
  createAppointmentDispatcher,
  appointmentData,
  prevStep,
  payloadStep1,
  comment,
  payloadStep2,
  customerOrFreeTextToDisplay,
  startOfAppointment,
  employeeToDisplay,
  endOfAppointment,
  selectedDate,
  loading,
}: ICreateAppointmentStep5SummaryProps) {
  const brandColor = useColorModeValue('brand.500', 'brand.100');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const { t } = useTranslation();
  const dataToBeDisplayed = {
    ...payloadStep1,
    ...payloadStep2,
    startOfAppointment,
    comment,
    endOfAppointment,
    customerId: customerOrFreeTextToDisplay,
    employeeId: employeeToDisplay,
  };
  console.log('🚀 ~ dataToBeDisplayed.employeeToDisplay:', dataToBeDisplayed);

  const { customerId, employeeId } = dataToBeDisplayed;
  const isCustomerObject = 'firstName' in customerId;
  return (
    <Box>
      <Flex direction="column" gap={2}>
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Avatar h="35px" w="35px" src={customerId?.photo?.path} />} />}
          name={t('Customer')}
          value={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center' }}>
              <Text isTruncated maxW="100%" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                {isCustomerObject ? `${customerId.firstName} ${customerId.lastName}` : customerId.value}
              </Text>
            </Box>
          }
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={BsScissors} color={brandColor} />} />}
          name={t('Treatments')}
          value={dataToBeDisplayed.treatments.map((treat) => treat.name).join(', ')}
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Avatar h="35px" w="35px" src={employeeId?.photo?.path} />} />}
          name={t('Employee')}
          value={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center' }}>
              <Text isTruncated maxW="100%" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                {employeeId.firstName} {employeeId.lastName}
              </Text>
            </Box>
          }
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={MdComment} color={brandColor} />} />}
          name={t('Comment')}
          value={dataToBeDisplayed.comment}
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" bg={boxBg} icon={<Icon w="22px" h="22px" as={BsCalendar} color={brandColor} />} />}
          name={t('Date')}
          value={
            <Box>
              {`${t('Day')} ${t(DAYS_OF_WEEK[selectedDate.getDay()])}`} - {selectedDate.toLocaleDateString('en-GB')}
            </Box>
          }
        />
        <MiniCard
          startContent={<IconBox w="36px" h="36px" icon={<Icon w="22px" h="22px" as={BsClock} color={brandColor} />} />}
          name={t('Time')}
          value={`${dataToBeDisplayed.startOfAppointment.substring(0, 5)} - ${dataToBeDisplayed.endOfAppointment.substring(0, 5)}`}
        />
      </Flex>
      <Flex justifyContent="end" mt={10} gap={3}>
        <Button variant="action" fontSize="md" fontWeight="500" px="24px" onClick={prevStep}>
          {t('Back')}
        </Button>
        <Button variant="brand" onClick={createAppointmentDispatcher} px="24px" isLoading={loading}>
          {appointmentData ? t('Update') : t('Create')}
        </Button>
      </Flex>
    </Box>
  );
}
