import axiosConfig from 'shared/axiosConfig';

export async function getBusinessCustomers(businessId: string, page: number, searchTerm: string) {
  const result = await axiosConfig.get(`/businesses/${businessId}/customers?page=${page}&searchTerm=${searchTerm}`);
  return result.data;
}

export async function getUpcomingBirthdayCustomers(businessId: string, page: number, searchTerm: string) {
  const result = await axiosConfig.get(`/businesses/${businessId}/customers/upcoming-birthday?page=${page}&searchTerm=${searchTerm}`);
  return result.data;
}

export async function getNewlyCreatedAppointments(businessId: string, page: number) {
  const result = await axiosConfig.get(`/businesses/${businessId}/appointments/newly-created?page=${page}`);
  return result.data;
}

export async function getBusinessBlockedCustomers(businessId: string) {
  const result = await axiosConfig.get(`/businesses/${businessId}/blocked-customers`);
  return result.data;
}
