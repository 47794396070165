import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { queryClient } from 'index';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { blockUnblockCustomer } from 'services/api/business';
import { useBusinessBlockedCustomers } from 'shared/hooks/useBusinessBlockedCustomers';
import { useBusinessCustomers } from 'shared/hooks/useBusinessCustomers';
import { CustomerEntity } from 'shared/types/app.types';

export default function Customers() {
  const { userData } = useContext(AuthenticationContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { t } = useTranslation();
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerEntity | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [page, setPage] = useState<number>(1);

  const bgColor = useColorModeValue('white', 'brand.700');
  const tableBorderColor = useColorModeValue('black', 'brand.700');
  const inputBorder = useColorModeValue('brand.590', 'brand.100');
  const headerBgColor = useColorModeValue('secondaryGray.200', 'brand.900');
  const headerTextColor = useColorModeValue('secondaryGray.900', 'white');
  const hoverBgColor = useColorModeValue('secondaryGray.100', 'brand.700');

  // Simulated query to fetch data
  const { customersData, isSuccessCustomers, isLoadingCustomers } = useBusinessCustomers({
    businessData: userData.business,
    page,
    searchTerm,
  });
  const { blockedCustomers, isSuccessBlockedCustomers } = useBusinessBlockedCustomers({
    businessData: userData.business,
  });
  const blockedIds = blockedCustomers.map((customer) => customer.customer.id);
  // Mutation for toggling the blocked state
  const {
    mutate: blockUnblockMutation,
    isError: isErrorBlockUnblockCustomer,
    isLoading: isLoadingBlockUnblockCustomer,
  } = useMutation(blockUnblockCustomer, {
    onSuccess: async (data) => {
      onClose();
      await queryClient.invalidateQueries('customers');
      await queryClient.invalidateQueries('blocked-customers');
      // Dynamically update the toast message based on action (block or unblock)
      const action = data.isBlocked ? t('Blocked') : t('Unblocked');
      const description = data.isBlocked ? t('The customer has been successfully blocked.') : t('The customer has been successfully unblocked.');
      toast({
        title: `${action} ${t('Successfully')}`,
        description: description,
        status: 'success',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('Oops! Something went wrong'),
        description: t("Failed to update the customer's status. Please try again."),
        status: 'error',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true,
      });
    },
  });

  // Handle switch toggle
  const handleToggle = (customer: CustomerEntity) => {
    setSelectedCustomer(customer);
    onOpen();
  };

  // Handle confirmation modal
  const handleConfirm = () => {
    if (selectedCustomer) {
      blockUnblockMutation({
        businessId: userData.business.id,
        businessPayload: {
          customerId: selectedCustomer.id,
          reason: reason,
        },
      });
    }
  };

  // Debounce function
  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (...args: any[]) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Debounced setSearchTerm function
  const debouncedSetSearchTerm = useCallback(debounce(setSearchTerm, 1500), []);

  // Filter customers by search term
  const filteredCustomers = customersData.items.filter(
    (customer) =>
      `${customer.firstName} ${customer.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()) || customer.phone.includes(searchTerm),
  );

  const isSelectedCustomerIsBlocked = blockedIds.includes(selectedCustomer?.id);

  return (
    <Box width="100%" py={4} px={2} mt={20} borderRadius="md">
      {/* Search Input */}
      <InputGroup mb={4}>
        <InputLeftElement pointerEvents="none">
          <SearchIcon />
        </InputLeftElement>
        <Input
          border={'1px solid'}
          borderColor={inputBorder}
          placeholder={t('Search by name Or Phone Number')}
          onChange={(e) => debouncedSetSearchTerm(e.target.value)}
        />
      </InputGroup>
      <Box display="flex" alignItems="center" mb={4} gap={2}>
        <Text>{t('Total Customers')}</Text>
        <Text>{customersData.total}</Text>
      </Box>

      {/* Table */}
      {isLoadingCustomers ? (
        <Flex justifyContent="center" alignItems="center" height="100%">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Box height="100%" overflowY="auto" maxHeight="900px">
          <Table variant="simple" bg={bgColor} border="1px solid" borderColor={tableBorderColor} borderRadius="md" shadow="md">
            <Thead bg={headerBgColor}>
              <Tr>
                <Th color={headerTextColor}>{t('Full Name')}</Th>
                <Th color={headerTextColor}>{t('Phone')}</Th>
                <Th color={headerTextColor}>{t('Birthday')}</Th>
                <Th color={headerTextColor}>{t('Blocked Reason')}</Th>
                <Th color={headerTextColor}>{t('Is Blocked')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredCustomers.map((customer) => (
                <Tr key={customer.id} _hover={{ bg: hoverBgColor }} border="1px solid" borderColor={tableBorderColor}>
                  <Td>{`${customer.firstName} ${customer.lastName}`}</Td>
                  <Td>{customer.phone}</Td>
                  <Td>{new Date(customer.birthDate).toLocaleDateString('en-GB')}</Td>
                  <Td>{blockedCustomers.find((bc) => bc.customer.id === customer.id)?.reason}</Td>
                  <Td>
                    <Switch
                      size="lg"
                      isChecked={blockedIds.includes(customer.id)}
                      sx={{ direction: 'ltr' }}
                      onChange={() => handleToggle(customer)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
      <Flex justifyContent={'center'} alignItems="center" py={4}>
        <Button
          onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))}
          disabled={page === 1}
          variant="action"
          leftIcon={<ChevronRightIcon />}
          mx={2}
        >
          {t('Previous')}
        </Button>
        <Text as="span" mx={4}>
          {page} / {Math.ceil(customersData.total / customersData.limit)}
        </Text>
        <Button
          onClick={() => setPage((prevPage) => prevPage + 1)}
          disabled={!customersData.hasMore}
          variant="brand"
          rightIcon={<ChevronLeftIcon />}
          mx={2}
        >
          {t('Next')}
        </Button>
      </Flex>

      {/* Modal for confirmation */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader sx={{ textAlign: 'center' }}>{t(isSelectedCustomerIsBlocked ? 'Unblock' : 'Block')}</ModalHeader>
          <Text as="span" mx={4} fontSize="md" fontWeight="bold">
            {selectedCustomer?.firstName} {selectedCustomer?.lastName}
          </Text>
          <ModalBody>
            {t(
              isSelectedCustomerIsBlocked
                ? 'Are you sure you want to remove customer from blocking list?'
                : 'Do you want to add this customer to the blocking list?',
            )}
            {!isSelectedCustomerIsBlocked && (
              <Textarea placeholder={t('What is the reason?')} size="md" onChange={(e: any) => setReason(e.target.value)} />
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              {t('Cancel')}
            </Button>
            <Button colorScheme="blue" mr={3} onClick={handleConfirm}>
              {t('Confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
