import { Avatar, Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { EventContentArg } from '@fullcalendar/core';
import { PhoneIcon } from 'components/icons/Icons';
import { useTranslation } from 'react-i18next';
import { BsPatchExclamationFill } from 'react-icons/bs';
import { MdTimer } from 'react-icons/md';
import { sumTreatmentsTime } from 'shared/helpersFunctions';

import { BusinessEntityType, EmployeeEntityType, TreatmentEntityType } from 'shared/types/app.types';

interface ITimeGridWeekViewProps {
  businessData: BusinessEntityType;
  treatments: TreatmentEntityType[];
  freeText: string;
  employee: EmployeeEntityType;
  absence: boolean;
  eventContent: EventContentArg;
  customer?: any;
}

export default function TimeGridWeekView({ businessData, customer, treatments, freeText, employee, absence, eventContent }: ITimeGridWeekViewProps) {
  const { t } = useTranslation();
  const { comment } = eventContent.event._def.extendedProps;
  const iconColor = useColorModeValue('brand.600', 'brand.300');
  const { timeText } = eventContent;
  const { startOfAppointment, endOfAppointment, createdByCustomer } = eventContent.event._def.extendedProps;
  const customerNameOrFreeText = customer ? `${customer.firstName} ${customer.lastName}` : freeText || '';
  const timeOfTreatment = sumTreatmentsTime(treatments);
  const firstTreatmentName = treatments[0]?.name || '';

  // Mobile view: Only show customer's first name and first treatment
  // For absence: still show Absence + employee name, but minimal.
  // For short appointments: also minimal.
  // For normal appointments: full details on larger screens.

  if (absence) {
    return (
      <Flex px={2} borderRadius="4px" width="100%" height="100%" alignItems="center" justifyContent="center" flexDirection="column">
        <Flex position="absolute" top="0" left="0" justifyContent="end" w="100%">
          {comment && <Icon as={BsPatchExclamationFill} color="red.600" width="30px" height="30px" />}
        </Flex>
        <Flex justifyContent="center" alignItems="center" flexDirection="column" gap={1} width="100%">
          <Text fontWeight="500" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" maxWidth="100%">
            {t('Absence')}
          </Text>
          <Text
            fontWeight="600"
            m="0px"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            maxWidth="100%"
            fontSize={{ base: 'sm', md: 'md' }}
          >
            {/* On mobile we keep minimal info but absence is already minimal */}
            {`${employee.firstName} ${employee.lastName}`}
          </Text>
        </Flex>
      </Flex>
    );
  }

  // For short appointments (timeOfTreatment <= 15), currently shows minimal info already.
  if (timeOfTreatment <= 20) {
    return (
      <Flex borderRadius="10px" position="relative" justifyContent="space-between" flexDirection={{ base: 'column', md: 'row' }}>
        {/* On mobile: only show customer's first name and first treatment in ellipsis */}
        <Flex position="absolute" top="0" left="0" justifyContent="end" w="100%">
          {comment && <Icon as={BsPatchExclamationFill} color="red.600" width="13px" height="13px" />}
        </Flex>
        <Flex flexDirection={'row'} maxW="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {createdByCustomer && (
            <Flex alignItems="center" gap={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              <PhoneIcon color={iconColor} h="18px" w="18px" />
            </Flex>
          )}
          <Flex alignItems="center" gap={1} display={{ base: 'none', md: 'flex' }} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {customer && <Avatar h="18px" w="18px" src={customer?.photo?.path} />}
            <Text fontSize="sm" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxW="100%">
              {customerNameOrFreeText}
            </Text>
          </Flex>
          <Text display={{ base: 'inline', md: 'none' }} fontSize="sm" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxW="100%">
            {customer ? customer.firstName : customerNameOrFreeText} - {firstTreatmentName}
          </Text>
        </Flex>

        <Flex display={{ base: 'none', md: 'flex' }} alignItems="center" gap={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          <Text
            fontSize="sm"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >{`${startOfAppointment.substr(0, 5)} - ${endOfAppointment.substr(0, 5)}`}</Text>
          <Icon as={MdTimer} w="18px" h="18px" />
        </Flex>
      </Flex>
    );
  }

  // Normal appointments
  return (
    <Flex borderRadius="10px" py="2px" px="2px" position="relative" justifyContent="space-between" overflow="hidden" maxW="100%">
      <Flex justifyContent="space-between" flexDirection="row" maxW="100%" alignItems="center" overflow="hidden">
        {createdByCustomer && <PhoneIcon color={iconColor} h="18px" w="18px" />}
        <Avatar h="35px" w="35px" src={customer?.photo?.path} />
        <Flex flexDirection="column" px={1} maxW="100%" overflow="hidden">
          <Text isTruncated maxW="100%" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {customerNameOrFreeText}
          </Text>
          <Text isTruncated maxW="100%" fontSize="xs" color="secondary.500" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {treatments.map((treat) => treat.name).join(', ')}
          </Text>
        </Flex>
      </Flex>

      <Box top={0} left={0} width="100%" pt={0.5} pl={1} textAlign="left" overflow="hidden" maxW="30%">
        <Flex justifyContent="flex-end" alignItems="center" overflow="hidden" maxW="100%">
          <Text isTruncated maxW="100%" fontSize="sm" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {`${startOfAppointment.substr(0, 5)} - ${endOfAppointment.substr(0, 5)}`}
          </Text>
          {comment && <Icon as={BsPatchExclamationFill} color="red.600" width="20px" height="30px" />}
        </Flex>
        <Flex alignItems="center" gap={0.5} justifyContent="flex-end" maxW="100%" overflow="hidden">
          <Icon as={MdTimer} w="18px" h="18px" />
          <Text isTruncated maxW="100%" fontSize="sm" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {`${timeOfTreatment} ${t('minutes')}`}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
}
