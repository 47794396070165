import { useQuery } from 'react-query';
import { getBusinessCustomers } from 'services/api/customers';
import { serializeCustomers } from 'shared/helpersFunctions';
import { BusinessEntityType, PaginatedResultCustomers } from 'shared/types/app.types';

export const useBusinessCustomers = ({ businessData, page, searchTerm }: { businessData: BusinessEntityType; page: number; searchTerm: string }) => {
  const emptyResponse = {
    items: [] as any[],
    total: 0,
    page: 1,
    limit: 10,
    hasMore: false,
  };
  const {
    data: result,
    isLoading: isLoadingCustomers,
    isSuccess: isSuccessCustomers,
  } = useQuery<PaginatedResultCustomers>(['customers', searchTerm, page], () => getBusinessCustomers(businessData.id, page, searchTerm), {
    select: (response) => {
      return response
        ? {
            ...response,
            items: serializeCustomers(response.items),
          }
        : emptyResponse;
    },
    refetchOnMount: false,
  });

  return { customersData: result ?? emptyResponse, isLoadingCustomers, isSuccessCustomers };
};
