// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { faCancel, faCheck, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Custom components
import IconBox from 'components/icons/IconBox';
import { ThreeDIconButton } from 'components/icons/ThreeDIconButton';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCancel, MdCheck } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { approveAppointment } from 'services/api/appointments';
import { APPOINTMENT_PARAM, UNREAD } from 'shared/consts';
import { displayDateReverse, throwError, timeSince } from 'shared/helpersFunctions';
import { NotificationItem } from 'shared/types/app.types';

export default function NotificationCard({
  notification,
  displayFromNavbar = false,
}: {
  notification: NotificationItem;
  displayFromNavbar?: boolean;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef();
  const finalRef = useRef();
  const { userData } = useContext(AuthenticationContext);
  const bgColor = useColorModeValue('gray.300', 'brand.700');
  const iconButtonColor = useColorModeValue('brand.400', 'brandScheme.500');
  const iconColor = useColorModeValue('brand.200', 'brand.300');
  const iconCancelButtonColor = useColorModeValue('red.400', 'red.500');
  const iconCancelColor = useColorModeValue('red.200', 'red.300');
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate: approveOrCancelMutation } = useMutation(approveAppointment, {
    onSuccess: async () => {
      toast({
        title: t('Appointment updated successfully'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      await queryClient.invalidateQueries('notifications');
    },
    onError: () => {
      toast({
        title: t('Error updating appointment'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleApproveOrCancelAppointment = (approve: boolean) => {
    if (!approve) {
      onOpen();
      return;
    }
    approveOrCancelMutation({
      appointmentId: notification.appointment.id,
      userId: userData.user.id,
      approveData: { approve, notificationId: notification.id },
    });
  };

  const cancelAppointment = () => {
    approveOrCancelMutation({
      appointmentId: notification.appointment.id,
      userId: userData.user.id,
      approveData: { approve: false, notificationId: notification.id },
    });
  };

  const renderNotificationTypeText = () => {
    switch (notification.notificationType) {
      case 'APPOINTMENT_CREATE':
        return t('Created new appointment');
      case 'APPOINTMENT_UPDATE':
        return t('Updated appointment');
      case 'APPOINTMENT_DELETE':
        return t('Deleted the appointment');
      case 'APPOINTMENT_TAKE_ACTION':
        return t('Created aew appointment that needs to take an action');
      default:
        throwError('Invalid notification type');
    }
  };

  const callCustomer = () => {
    return (window.location.href = `tel:${notification.customer.phone}`);
  };

  const renderNotificationTagColor = () => {
    switch (notification.notificationType) {
      case 'APPOINTMENT_CREATE':
        return '#32CD32'; // Light green
      case 'APPOINTMENT_UPDATE':
        return '#FFA500'; // Orange
      case 'APPOINTMENT_DELETE':
        return '#FF0000'; // Red
      default:
        throwError('Invalid notification type');
    }
  };

  const renderNotificationTagBody = () => {
    switch (notification.notificationType) {
      case 'APPOINTMENT_CREATE':
        return (
          <Flex alignItems={'center'}>
            <IconBox w="12px" h="12px" icon={<FontAwesomeIcon size="lg" icon={faCheck} />} />
          </Flex>
        );
      case 'APPOINTMENT_UPDATE':
        return (
          <Flex alignItems={'center'}>
            <IconBox w="12px" h="12px" icon={<FontAwesomeIcon size="lg" icon={faClock} />} />
          </Flex>
        );
      case 'APPOINTMENT_DELETE':
        return (
          <Flex alignItems={'center'}>
            <IconBox w="12px" h="12px" icon={<FontAwesomeIcon size="lg" icon={faCancel} />} />
          </Flex>
        );
      default:
        throwError('Invalid notification type');
    }
  };

  const navigateToCalendar = () => {
    if (
      notification.notificationType === 'APPOINTMENT_CREATE' ||
      notification.notificationType === 'APPOINTMENT_UPDATE' ||
      notification.notificationType === 'APPOINTMENT_TAKE_ACTION'
    ) {
      const url = `/admin/calendar?${APPOINTMENT_PARAM}=${encodeURIComponent(notification.appointment.id)}`;
      const appointmentDate = new Date(notification.appointment.dateOfAppointment);
      appointmentDate.setDate(appointmentDate.getDate() + 1);
      const fromDate = appointmentDate.toLocaleDateString();
      const toDateStr = appointmentDate.toLocaleDateString();
      navigate(url, {
        state: {
          ...notification.appointment,
          customerId: notification.customer,
          employeeId: notification.appointment.employeeId,
          chosenDates: {
            fromDate,
            toDate: toDateStr,
            day: new Date(notification.appointment.dateOfAppointment).getDay(),
            date: new Date(notification.appointment.dateOfAppointment),
            view: 'timeGridDay',
          },
        },
      });
    }
  };

  const { treatments, startOfAppointment, dateOfAppointment } = notification.appointment;

  return (
    <Box
      onClick={navigateToCalendar}
      py={3}
      px={1}
      cursor={'pointer'}
      borderBottom={'1px solid gray'}
      bgColor={notification.status === UNREAD && !displayFromNavbar ? bgColor : ''}
    >
      <Flex
        my="auto"
        alignItems={'center'}
        h="100%"
        align={{ base: 'center', xl: 'start' }}
        justify={{ base: 'start', xl: 'start' }}
        justifyContent={'space-between'}
      >
        <Box>
          <Flex alignItems={'center'}>
            <Box borderRadius={'50%'} p={1} mx={1} bgColor={notification.status === UNREAD && !displayFromNavbar ? 'brand.400' : 'transparent'} />

            <Avatar
              h={{ base: '48px', xl: '36px', '2xl': '48px' }}
              w={{ base: '48px', xl: '36px', '2xl': '48px' }}
              name={`${notification.customer?.firstName} ${notification.customer?.lastName}`}
              src={notification.customer?.photo?.path ?? ''}
              me="6px"
            />
            <Flex>
              <Text mx={1} fontSize="md" fontWeight="500">
                {displayFromNavbar
                  ? renderNotificationTypeText()
                  : `${renderNotificationTypeText()} ${t('for')} - ${displayDateReverse(dateOfAppointment)} ${t(
                      'at',
                    )} - ${startOfAppointment.substring(0, 5)} ${t('for')} - 
                    `}
                {!displayFromNavbar &&
                  treatments.map((t, index) => <span key={t.name}>{`${t.name}${index < treatments.length - 1 ? ', ' : '.'}`}</span>)}
              </Text>
            </Flex>
          </Flex>
          <Flex mt={1} px={16} alignItems="center" justifyContent={'space-between'}>
            <Text mx={2} fontSize="sm" color={'gray.500'} fontWeight="500">
              {timeSince(notification.createdAt)}
            </Text>
          </Flex>
        </Box>
        <Flex flexDirection={'column'} justifyContent="space-around" gap={3}>
          {notification.notificationType === 'APPOINTMENT_TAKE_ACTION' ? (
            <Flex gap={4} flexDirection="column">
              <ThreeDIconButton
                icon={MdCancel}
                iconColor={iconCancelColor}
                icoButtonColor={iconCancelButtonColor}
                onClick={() => handleApproveOrCancelAppointment(false)}
              />
              <ThreeDIconButton
                icon={MdCheck}
                iconColor={iconColor}
                icoButtonColor={iconButtonColor}
                onClick={() => handleApproveOrCancelAppointment(true)}
              />
            </Flex>
          ) : (
            <Tag py={2} size={'md'} borderRadius="full" variant="solid" bgColor={renderNotificationTagColor()}>
              <TagLabel>{renderNotificationTagBody()}</TagLabel>
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal size="xl" initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} scrollBehavior="outside" onClose={onClose}>
        <ModalOverlay />
        <ModalContent sx={{ textAlign: 'center' }}>
          <ModalHeader>{t('Decline Appointment')}</ModalHeader>
          <ModalCloseButton />
          <Box p={10} display="flex" gap={2} flexDirection="column">
            <Text mb={4}>{t('Are you sure you want to decline the appointment?')}</Text>
            <Button variant={'brand'} onClick={cancelAppointment}>
              {t('Decline')}
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
}
