// First we need to import axios.js
import axios from 'axios';
import { USER_DATA_KEY } from './consts';
import { TokenResponseType } from './types/app.types';

// Next we make an 'instance' of it
const axiosConfig = axios.create({
  // .. where we make our configurations
  baseURL: 'https://api.easy-book.io/api/v1/',
  // baseURL: 'http://localhost:8080/api/v1/',
  withCredentials: true, // Ensure cookies or credentials are sent with requests
  headers: {
    'Content-Type': 'application/json', // Avoid unnecessary headers
  },
});

// // Where you would set stuff like your 'Authorization' header, etc ...
const userToken: TokenResponseType = JSON.parse(localStorage.getItem(USER_DATA_KEY));
if (userToken) {
  axiosConfig.defaults.headers.common['Authorization'] = 'Bearer ' + userToken.token || '';
}

// Also add/configure interceptors && all the other cool stuff

axiosConfig.interceptors.request.use(
  (request) => {
    // You can inspect or modify the request before it is sent
    return request;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  },
);

axiosConfig.interceptors.response.use(
  (response) => {
    // Modify the response data if needed
    return response;
  },
  (error) => {
    // Handle response errors
    if (error.response && error.response.status === 401) {
      localStorage.setItem(USER_DATA_KEY, null); // Clear user token on unauthorized error
      window.location.reload(); // Reload the page on unauthorized error
    } else if (error.response && error.response.status === 403) {
      alert('You do not have permission to perform this action.');
    }
    return Promise.reject(error);
  },
);

export default axiosConfig;
