import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import { MultiValue, Select as MultiSelect } from 'chakra-react-select';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPeople } from 'react-icons/bs';
import { employeesSelectWrapper } from 'shared/components/SelectComponents';
import { EmployeeSelectType } from 'shared/types/app.types';

export const EmployeesInput = ({
  employees,
  isLoadingEmployees,
  chosenEmployees,
  setChosenEmployees,
}: {
  employees: EmployeeSelectType[];
  isLoadingEmployees: boolean;
  chosenEmployees: EmployeeSelectType[];
  setChosenEmployees: Dispatch<SetStateAction<EmployeeSelectType[]>>;
}) => {
  const { t } = useTranslation();
  const ALL_CREW = t('All Crew');
  const allEmployeesOption = { value: t(ALL_CREW), label: t(ALL_CREW), Icon: BsPeople, iconColor: 'brand.900' } as EmployeeSelectType;
  const [inputValues, setInputValues] = useState<EmployeeSelectType[]>([allEmployeesOption]);
  const options = [...employees, allEmployeesOption];
  const color = useColorModeValue('white', 'brand.500');

  const handleEmployeesChosen = (data: MultiValue<EmployeeSelectType>) => {
    const allInputValuesLabels = inputValues.map((value) => value.label);
    const newAddedValue = data.find((option) => !allInputValuesLabels.includes(option.label));
    if ((!newAddedValue && data.length === 0) || newAddedValue?.label === ALL_CREW) {
      setInputValues([allEmployeesOption]);
      setChosenEmployees([]);
      return;
    }
    const filteredOptions = data.filter((option) => option.value !== ALL_CREW);
    setInputValues([...filteredOptions]);
    setChosenEmployees([...filteredOptions]);
  };

  return (
    <Box
      w="90%"
      bgColor={'red'}
      sx={{
        position: 'fixed',
        width: '200px',
        bottom: { base: '3px', lg: '20px' },
        justifyContent: 'center',
        right: { base: '92px', lg: 420 },
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <MultiSelect
        isMulti
        value={inputValues}
        size="md"
        name="employees-calendar"
        components={employeesSelectWrapper({
          selectInputWidth: '250px',
          selectInputBgColor: color,
        })}
        isClearable={false}
        options={options}
        isLoading={isLoadingEmployees}
        variant="auth"
        menuPlacement="top"
        placeholder={
          <Text textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" fontWeight="500">
            {t('Choose Employee/s')}
          </Text>
        }
        onChange={(employees) => handleEmployeesChosen(employees)}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 3000,
          }),
          input: (provided) => ({
            ...provided,
            zIndex: 1000,
          }),
        }}
      />
    </Box>
  );
};
