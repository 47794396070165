import { useQuery } from 'react-query';
import { getNewlyCreatedAppointments } from 'services/api/customers';
import { NEWLY_CREATED_APPOINTMENTS_QUERY_KEY } from 'shared/consts';
import { BusinessEntityType, PaginatedResultNewlyCreatedAppointments } from 'shared/types/app.types';

export const useNewlyCreatedAppointments = ({ businessData, page }: { businessData: BusinessEntityType; page: number }) => {
  const emptyResponse = {
    items: [] as any[],
    total: 0,
    page: 1,
    limit: 10,
    hasMore: false,
  };
  const {
    data: result,
    isLoading: isLoadingNewlyCreatedAppointments,
    isSuccess: isSuccessNewlyCreatedAppointments,
  } = useQuery<PaginatedResultNewlyCreatedAppointments>(
    [NEWLY_CREATED_APPOINTMENTS_QUERY_KEY, page],
    () => getNewlyCreatedAppointments(businessData.id, page),
    {
      refetchOnMount: false,
    },
  );

  return { newlyCreatedAppointmentsData: result ?? emptyResponse, isLoadingNewlyCreatedAppointments, isSuccessNewlyCreatedAppointments };
};
