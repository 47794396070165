import {
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';
import { DateSelectArg } from '@fullcalendar/core';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import CreateAppointmentStep1Form from 'components/forms/createOrUpdateAppointment/CreateAppointmentStep1Form';
import CreateAppointmentStep2Form from 'components/forms/createOrUpdateAppointment/CreateAppointmentStep2Form';
import CreateAppointmentStep4Form from 'components/forms/createOrUpdateAppointment/CreateAppointmentStep4Form';
import CreateAppointmentStep5Summary from 'components/forms/createOrUpdateAppointment/CreateAppointmentStep5Summary';
import { AuthenticationContext } from 'providers/AuthProvider';
import { CalendarContext } from 'providers/CalendarProvider';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBrush, BsCalendar, BsCheck, BsPerson } from 'react-icons/bs';
import { MdClose, MdComment, MdTimer } from 'react-icons/md';
import { addMinutesToTime, getCommonEmployeesFromTreatments, getInitDate, serializeEmployees, setUpTreatmentTime } from 'shared/helpersFunctions';
import {
  AppointmentEntity,
  CreateAppointmentPayloadStep1,
  CreateAppointmentPayloadStep2,
  EmployeeSelectType,
  TreatmentSelectType,
  UpdateAppointmentDto,
} from 'shared/types/app.types';

interface ICreateAppointmentModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onEditOrDeleteDrawerClose: () => void;
  minOpenTime: string;
  businessWorkingDaysWeekNumbers: number[];
  appointmentData?: AppointmentEntity;
  createAppointmentDatesData?: DateSelectArg;
}

export default function CreateOrEditAppointmentModal({
  isOpen,
  onEditOrDeleteDrawerClose,
  closeModal,
  appointmentData,
  createAppointmentDatesData,
  businessWorkingDaysWeekNumbers,
  minOpenTime,
}: ICreateAppointmentModalProps) {
  const stepsColor = useColorModeValue('orange.600', 'orange.400');
  const { t } = useTranslation();
  const { userData } = useContext(AuthenticationContext);
  const [comment, setComment] = useState<string>(appointmentData?.comment ?? '');
  const [selectedTime, setSelectedTime] = useState<string>(setUpTreatmentTime(minOpenTime, createAppointmentDatesData, appointmentData));

  const [selectedDate, setSelectedDate] = useState<Date>(getInitDate(appointmentData, createAppointmentDatesData));
  const [employeesOptions, setEmployeesOptions] = useState<EmployeeSelectType[]>([]);
  const [customerOrFreeTextToDisplay, setCustomerOrFreeTextToDisplay] = useState<any>();
  const [employeeToDisplay, setEmployeeToDisplay] = useState<EmployeeSelectType>();
  const [payloadStep1, setPayloadStep1] = useState<CreateAppointmentPayloadStep1 | null>();
  const [payloadStep2, setPayloadStep2] = useState<CreateAppointmentPayloadStep2 | null>();
  const initialRef = useRef();
  const finalRef = useRef();
  const { createAppointmentMutation, updateAppointmentMutation, isLoadingAppointmentCreation, isLoadingAppointmentUpdate } =
    useContext(CalendarContext);

  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const setUpStep1 = (payloadStep1: CreateAppointmentPayloadStep1, chosenTreatments: TreatmentSelectType[], chosenCustomer: any) => {
    const commonEmployees = getCommonEmployeesFromTreatments(chosenTreatments);
    const serializedEmployees = serializeEmployees(commonEmployees);
    setCustomerOrFreeTextToDisplay(chosenCustomer);
    setPayloadStep1(payloadStep1);
    setEmployeesOptions(serializedEmployees);
    nextStep();
    if (serializedEmployees.length === 1) {
      setPayloadStep2({ employeeId: commonEmployees[0].id });
      setEmployeeToDisplay(serializedEmployees[0]);
      nextStep();
    }
  };

  const setUpStep2 = (payloadStep2: CreateAppointmentPayloadStep2, chosenEmployee: EmployeeSelectType) => {
    setPayloadStep2(payloadStep2);
    setEmployeeToDisplay(chosenEmployee);
    nextStep();
  };

  const setUpStep3 = () => {
    nextStep();
  };

  const createAppointmentDispatcher = () => {
    const endOfAppointment = addMinutesToTime(
      selectedTime,
      payloadStep1
        ? payloadStep1.treatments.map((treat) => +treat.timeOfTreatment).reduce((acc, newTime) => acc + newTime)
        : appointmentData.treatments.map((treat) => +treat.timeOfTreatment).reduce((acc, newTime) => acc + newTime),
    );
    if (appointmentData) {
      // updating the appointment
      updateAppointmentMutation({
        updatedAppointment: {
          ...appointmentData,
          ...payloadStep1,
          ...payloadStep2,
          comment: comment,
          startOfAppointment: selectedTime,
          endOfAppointment: endOfAppointment,
          dateOfAppointment: selectedDate.toISOString().split('T')[0],
        } as UpdateAppointmentDto,
        appointmentId: appointmentData.id,
        onClose: () => {
          closeModal();
          onEditOrDeleteDrawerClose();
        },
      });
    } else {
      // creating new appointment
      createAppointmentMutation({
        ...payloadStep1,
        ...payloadStep2,
        comment: comment,
        startOfAppointment: selectedTime,
        endOfAppointment: endOfAppointment,
        dateOfAppointment: selectedDate.toISOString().split('T')[0],
        prevStep,
        closeModal,
      });
    }
  };

  return (
    <Modal
      size="lg"
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      scrollBehavior={'outside'}
      closeOnOverlayClick={false}
      onClose={closeModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex gap="5px" alignItems={'center'}>
            <Button variant="action" ml={3} onClick={closeModal}>
              <Icon as={MdClose} width="20px" height="20px" color="inherit" />
            </Button>
            <Flex gap={0.5} flexDirection="column" justifyContent={'center'} margin="auto" textAlign={'center'}>
              <Text>{appointmentData ? t('Update Appointment') : t('Create Appointment at')}</Text>
              <Flex
                gap={3}
                justifyContent="center"
                py={1}
                px={4}
                borderRadius={'24px'}
                textAlign={'center'}
                border={'1px solid'}
                borderColor={stepsColor}
              >
                <Text>{selectedDate.toLocaleDateString('en-GB')}</Text>
                <Text>{selectedTime}</Text>
              </Flex>
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody pb={2}>
          <Steps
            variant={'circles'}
            colorScheme={'orange'}
            orientation="vertical"
            sx={{
              '& .cui-steps__vertical-step:not(:last-child):after': {
                bg: stepsColor,
              },
            }}
            activeStep={activeStep}
          >
            <Step
              label={
                <Flex alignItems={'center'} gap={2}>
                  <Text>{t('Customer And Treatment')}</Text>
                  <Flex>
                    <Icon as={BsPerson} display="flex" width="20px" height="20px" />
                    <Icon as={BsBrush} display="flex" width="20px" height="20px" />
                  </Flex>
                </Flex>
              }
              key={1}
            >
              <CreateAppointmentStep1Form appointmentData={appointmentData} setUpStep1={setUpStep1} />
            </Step>
            <Step
              label={
                <Flex alignItems={'center'} gap={2}>
                  <Text>{t('Employee')}</Text>
                  <Flex>
                    <Icon as={BsPerson} display="flex" width="20px" height="20px" />
                  </Flex>
                </Flex>
              }
              key={2}
            >
              <CreateAppointmentStep2Form
                appointmentData={appointmentData}
                employees={employeesOptions ?? []}
                prevStep={prevStep}
                setUpStep2={setUpStep2}
              />
            </Step>
            <Step
              label={
                <Flex alignItems={'center'} gap={2}>
                  <Text>{t('Comment')}</Text>
                  <Flex>
                    <Icon as={MdComment} display="flex" width="20px" height="20px" />
                  </Flex>
                </Flex>
              }
              key={3}
            >
              <Box sx={{ mt: 1 }}>
                <FormLabel htmlFor="comment">{t('Comment')}</FormLabel>
                <Textarea
                  value={comment}
                  onChange={(element) => setComment(element.target.value)}
                  placeholder={t('Add your comment here')}
                  size="lg"
                />
                <Flex sx={{ justifyContent: 'end' }} my={3}>
                  <Button variant="action" fontSize="md" fontWeight="500" px="24px" onClick={prevStep} py="5px">
                    {t('Back')}
                  </Button>
                  <Button variant="brand" mx={2} onClick={nextStep} px="24px">
                    {t('Next')}
                  </Button>
                </Flex>
              </Box>
            </Step>
            <Step
              label={
                <Flex alignItems={'center'} gap={2}>
                  <Text>{t('Date & Time')}</Text>
                  <Icon as={BsCalendar} display="flex" width="20px" height="20px" />
                  <Icon as={MdTimer} display="flex" width="25px" height="25px" />
                </Flex>
              }
              key={4}
            >
              <CreateAppointmentStep4Form
                businessId={userData.business.id}
                employeeId={payloadStep2?.employeeId}
                treatments={payloadStep1?.treatments}
                setUpStep3={setUpStep3}
                appointmentData={appointmentData}
                prevStep={prevStep}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
                isValidDayOfBusiness={businessWorkingDaysWeekNumbers.includes(selectedDate.getDay())}
              />
            </Step>
            <Step
              label={
                <Flex alignItems={'center'} gap={1}>
                  <Text>{t('Summary')}</Text>
                  <Icon as={BsCheck} display="flex" width="28px" height="28px" />
                </Flex>
              }
              key={5}
            >
              <CreateAppointmentStep5Summary
                createAppointmentDispatcher={createAppointmentDispatcher}
                appointmentData={appointmentData}
                prevStep={prevStep}
                customerOrFreeTextToDisplay={customerOrFreeTextToDisplay}
                comment={comment}
                employeeToDisplay={employeeToDisplay}
                payloadStep1={payloadStep1}
                payloadStep2={payloadStep2}
                selectedDate={selectedDate}
                loading={isLoadingAppointmentCreation || isLoadingAppointmentUpdate}
                startOfAppointment={selectedTime}
                endOfAppointment={addMinutesToTime(
                  selectedTime,
                  payloadStep1
                    ? payloadStep1?.treatments.map((treat) => +treat.timeOfTreatment).reduce((acc, newTime) => acc + newTime)
                    : appointmentData?.treatments.map((treat) => +treat.timeOfTreatment).reduce((acc, newTime) => acc + newTime),
                )}
              />
            </Step>
          </Steps>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
