// Chakra imports
import { Box, Button, Flex, Heading, Icon, Spinner, useColorModeValue } from '@chakra-ui/react';
import NotificationCard from 'components/cards/NotificationCard';
import IconBox from 'components/icons/IconBox';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheck, MdNotifications } from 'react-icons/md';
import { useNotifStatusMutation } from 'shared/hooks/mutations/useNotifStatusMutation';
import { useNotifications } from 'shared/hooks/useNotifications';
import { NotificationItem } from 'shared/types/app.types';

export default function Notifications() {
  const { userData } = useContext(AuthenticationContext);
  const brandColor = useColorModeValue('brand.500', 'brand.100');
  const { t } = useTranslation();
  const businessId = userData.business.id;
  const { updateNotificationsStatusMutation, isLoadingStatusChange } = useNotifStatusMutation();
  const { notifications, isLoadingNotifications } = useNotifications({
    businessId,
  });

  const handleReadAllNotificationsClick = () => {
    if (notifications.length > 0) {
      updateNotificationsStatusMutation({
        businessId: userData.business.id,
        notificationsIds: {
          ids: notifications.map((noti) => noti.id),
        },
      });
    }
  };

  return (
    <Box pt={{ base: '100px', md: '90px', xl: '90px' }} display="flex" flexDirection={'column'}>
      {isLoadingNotifications && <Spinner />}
      {notifications.length === 0 && (
        <Flex px={{ base: '10px', md: '10px' }} alignItems="center">
          <Heading size="md" fontWeight={'700'}>
            {t('You have no notifications')}
          </Heading>
          <IconBox w="36px" h="36px" icon={<Icon as={MdNotifications} display="flex" color={brandColor} width="20px" height="20px" />} />
        </Flex>
      )}
      {notifications.map((notification: NotificationItem) => {
        return <NotificationCard key={notification.id} notification={notification} />;
      })}
      <Flex
        w="100%"
        gap={2}
        justifyContent={'center'}
        sx={{
          position: 'fixed',
          bottom: 5,
        }}
      >
        <Button variant="brand" fontSize="md" onClick={handleReadAllNotificationsClick} rightIcon={<Icon as={MdCheck} h="18px" w="18px" />}>
          {t('Mark all read')}
        </Button>
      </Flex>
    </Box>
  );
}
