import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { EventContentArg } from '@fullcalendar/core';
import { PhoneIcon } from 'components/icons/Icons';
import { useTranslation } from 'react-i18next';

import { EmployeeEntityType, TreatmentEntityType } from 'shared/types/app.types';

interface IDayGridMonthViewProps {
  // businessData: BusinessEntityType;
  employee: EmployeeEntityType;
  treatments: TreatmentEntityType[];
  freeText: string;
  eventContent: EventContentArg;
  absence: boolean;
  customer?: any;
}

export default function DayGridMonthView({ customer, treatments, freeText, absence, eventContent, employee }: IDayGridMonthViewProps) {
  const { t } = useTranslation();
  const iconColor = useColorModeValue('brand.600', 'brand.300');
  if (absence) {
    return null;
  }

  const { timeText } = eventContent;
  const { createdByCustomer } = eventContent.event._def.extendedProps;
  const customerNameOrFreeText = customer ? customer.firstName : freeText || '';
  const firstTreatmentName = treatments[0]?.name || '';
  const mobileDisplay = `${customerNameOrFreeText}${firstTreatmentName ? ` - ${firstTreatmentName}` : ''}`;

  return (
    <Flex
      px={2}
      borderRadius="4px"
      width="100%"
      height="100%"
      justifyContent="space-between"
      flex={8}
      gap={2}
      flexDirection={{ base: 'column', md: 'row' }}
    >
      {/* Mobile view: Only first name and first treatment */}
      <Flex display={{ base: 'flex', md: 'none' }} alignItems="center" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {createdByCustomer && <PhoneIcon color={iconColor} h="18px" w="18px" />}
        <Text textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" fontWeight="700">
          {mobileDisplay}
        </Text>
      </Flex>

      {/* Desktop view: Full details */}
      <Flex display={{ base: 'none', md: 'flex' }} flex={3} overflow="hidden" whiteSpace="nowrap">
        {createdByCustomer && (
          <Flex alignItems="center" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            <PhoneIcon color={iconColor} h="18px" w="18px" />
          </Flex>
        )}
        <Text textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" fontWeight="700">
          {customerNameOrFreeText}
        </Text>
      </Flex>
      <Flex display={{ base: 'none', md: 'flex' }} flex={2}>
        <Text textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" fontWeight="400">
          {timeText}
        </Text>
      </Flex>
      <Flex display={{ base: 'none', md: 'flex' }} flex={3} justifyContent="end" overflow="hidden" whiteSpace="nowrap">
        {treatments.map((treat, index) => (
          <Text overflow="hidden" whiteSpace="nowrap" key={treat.name} fontWeight="500" textOverflow="ellipsis">
            {` ${treat.name}${index < treatments.length - 1 ? ',' : ''}`}
          </Text>
        ))}
      </Flex>
    </Flex>
  );
}
