// EasyBookErrorBoundary.tsx
import { Box, Button, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { t } from 'i18next';
import React, { ErrorInfo, ReactNode } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { IoRefresh } from 'react-icons/io5';
import { Logo } from './logo/Logo';

interface EasyBookErrorBoundaryProps {
  children: ReactNode;
}

interface EasyBookErrorBoundaryState {
  hasError: boolean;
}

function ErrorScreen() {
  const bgColor = useColorModeValue('brand.100', 'brand.100');
  return (
    <Box display="flex" w="100vw" h="100vh" bg={bgColor} flexDirection="column" textAlign={'center'} alignItems="center" justifyContent="center">
      <Logo />
      <Icon mt={2} as={FaExclamationTriangle} boxSize="50px" color="red.500" />
      <Text fontSize="2xl" mt={4}>
        {t('Whoops! Something went wrong, but we are on it.')}
      </Text>
      <Button variant="brand" mt={2} onClick={() => window.location.reload()} leftIcon={<Icon as={IoRefresh} h="18px" w="18px" />}>
        {t('Refresh Page')}
      </Button>
    </Box>
  );
}

export class EasyBookErrorBoundary extends React.Component<EasyBookErrorBoundaryProps, EasyBookErrorBoundaryState> {
  constructor(props: EasyBookErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): EasyBookErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error('EasyBook ErrorBoundary caught:', error, info);
    // Optionally log to a monitoring service
  }

  render() {
    if (this.state.hasError) {
      return <ErrorScreen />;
    }
    return this.props.children;
  }
}
