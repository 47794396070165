// Chakra imports
import { Flex, Stat, StatLabel, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/cards/Card';

export default function MiniCard(props: {
  startContent?: JSX.Element;
  endContent?: JSX.Element;
  name: string;
  growth?: string | number;
  value: string | number | JSX.Element;
  businessColor?: string;
}) {
  const { startContent, endContent, name, value, businessColor } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';

  return (
    <Card py="8px" className="shadow-md" borderRadius="24px" border="1px solid" borderColor="gray.200">
      <Flex alignItems={'center'} h="100%" align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
        {startContent}

        <Stat ms={startContent ? '10px' : '0px'}>
          <StatLabel
            lineHeight="100%"
            color={textColorSecondary}
            fontSize={{
              base: 'sm',
            }}
            sx={{ marginBottom: '5px' }}
          >
            {name}
          </StatLabel>
          <Text color={businessColor ?? textColor} fontWeight="700">
            {value}
          </Text>
        </Stat>
        <Flex ms="auto" w="max-content">
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
