import { Box, Button, Divider, Flex, FormControl, SimpleGrid, Spinner, Tag, TagLabel, Text } from '@chakra-ui/react';
import { css, keyframes } from '@emotion/react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getEmployeeAvailabilityTimes } from 'services/api/appointments';
import { AppointmentEntity, TreatmentEntityType } from 'shared/types/app.types';

const vibrationAnimation = keyframes`
0% {
  transform: translateY(-2px) rotate(10deg) scale(1);
  }
  50% {
    transform: translateY(2px) rotate(-5deg) scale(1.6);
  }
  100% {
    transform: translateY(-2px) rotate(5deg) scale(1);
  }
  `;

const tagStyles = css`
  &:hover {
    animation: ${vibrationAnimation} 0.7s ease-in-out;
  }
`;

interface ICreateAppointmentStep4FormProps {
  setSelectedTime: (val: string) => void;
  setSelectedDate: (val: Date) => void;
  setUpStep3: () => void;
  prevStep: () => void;
  selectedDate: Date;
  isValidDayOfBusiness: boolean;
  selectedTime: string;
  employeeId: string;
  treatments: TreatmentEntityType[];
  businessId: string;
  appointmentData?: AppointmentEntity;
}

interface TimeSlotProps {
  title: string;
  slots: string[];
  selectedTime: string;
  setSelectedTime: (val: string) => void;
}

const TimeSlot: React.FC<TimeSlotProps> = ({ title, slots, selectedTime, setSelectedTime }) => {
  const [showAll, setShowAll] = useState(false);
  const maxSlots = 8;
  const containerRef = useRef(null); // Ref for dynamic height
  const [gridHeight, setGridHeight] = useState('auto');

  useEffect(() => {
    if (containerRef.current) {
      const minHeight = slots.length > 4 ? '80px' : '40px';
      setGridHeight(showAll ? `${containerRef.current.scrollHeight}px` : minHeight); // Adjust collapsed height
    }
  }, [showAll, slots]);

  const displayedSlots = showAll ? slots : slots.slice(0, maxSlots);

  return (
    <>
      {slots.length > 0 && (
        <>
          <Flex justifyContent="center" mb={2} mx={4}>
            <Text fontSize="22px" fontWeight="700">
              {title}
            </Text>
          </Flex>

          <div
            ref={containerRef}
            style={{
              overflow: 'hidden',
              transition: 'height 0.6s ease-in-out',
              height: gridHeight,
            }}
          >
            <SimpleGrid columns={[2, 3, 4]} spacing={3} mb={4}>
              {displayedSlots.map((time) => (
                <Tag
                  key={time}
                  onClick={() => setSelectedTime(time)}
                  size="md"
                  boxShadow="sm"
                  borderRadius="full"
                  variant="solid"
                  colorScheme="purple"
                  cursor="pointer"
                  sx={{
                    border: `4px solid ${time === selectedTime ? 'orange' : 'transparent'}`,
                  }}
                >
                  <TagLabel py={1} margin="auto">
                    {time}
                  </TagLabel>
                </Tag>
              ))}
            </SimpleGrid>
          </div>

          {slots.length > maxSlots && (
            <Button onClick={() => setShowAll(!showAll)} variant="action" size="sm">
              {showAll ? t('Show Less') : t('Show More')}
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default function CreateAppointmentStep4Form({
  appointmentData,
  employeeId,
  treatments,
  businessId,
  selectedTime,
  isValidDayOfBusiness,
  setUpStep3,
  setSelectedDate,
  selectedDate,
  prevStep,
  setSelectedTime,
}: ICreateAppointmentStep4FormProps) {
  const { t } = useTranslation();
  const timeOfTreatment = appointmentData?.startOfAppointment;
  const dateOfAppointment = new Date(appointmentData?.dateOfAppointment);
  const totalTime = treatments.map((treat) => +treat.timeOfTreatment).reduce((acc, time) => acc + time, 0);
  const { data: employeeAvailableSlots, isLoading: isLoadingEmployeeAvailableSlots } = useQuery<string[]>(
    ['employee-availability', selectedDate],
    () => getEmployeeAvailabilityTimes(businessId, employeeId, totalTime, selectedDate),
    {
      select: (response: string[]) => {
        const onTheSameDay = dateOfAppointment.getDate() === selectedDate.getDate() && dateOfAppointment.getMonth() === selectedDate.getMonth();
        return timeOfTreatment && onTheSameDay ? [...new Set([timeOfTreatment.substr(0, 5), ...response])] : response;
      },
      enabled: !!employeeId && isValidDayOfBusiness,
    },
  );

  const morningAvailableSlots = (employeeAvailableSlots ?? []).filter((item) => {
    const [hours] = item.split(':').map(Number);
    return hours < 12; // all under 11:59
  });

  const afternoonAvailableSlots = (employeeAvailableSlots ?? []).filter((item) => {
    const [hours] = item.split(':').map(Number);
    return hours >= 12 && hours < 16; // all between 12:00 - 15:59
  });

  const eveningAvailableSlots = (employeeAvailableSlots ?? []).filter((item) => {
    const [hours] = item.split(':').map(Number);
    return hours >= 16; // all the rest from 16:00 - 23:00
  });

  return (
    <Box>
      <Flex gap={2} mt={2} justifyContent="center">
        <DesktopDatePicker
          label={t('Select Date')}
          value={moment(selectedDate)}
          onChange={(newValue) => setSelectedDate(newValue?.toDate())}
          format="DD/MM/YYYY"
          slotProps={{
            textField: {
              error: !employeeAvailableSlots || employeeAvailableSlots.length === 0,
              InputProps: {
                sx: {
                  borderRadius: '12px',
                  py: '0px',
                  fontSize: '18px',
                  color: '#333',
                  backgroundColor: '#f9f9f9',
                  '&:hover': {},
                  '&.Mui-focused': {},
                },
              },
              inputProps: {
                sx: {
                  textAlign: 'center',
                },
              },
            },
            popper: {
              sx: { zIndex: 9999 },
            },
          }}
          sx={{ zIndex: 1, width: '80%', direction: 'ltr' }}
        />
      </Flex>

      <Flex py={1} alignItems="center" justifyContent="space-around" gap={1} borderRadius="20px" />
      <Divider width="100%" />

      <FormControl mb="20px" isRequired>
        {!isValidDayOfBusiness && (
          <Flex>
            <Text fontSize="18px" color="red.400" fontWeight="700">
              {t('Business is close')}
            </Text>
          </Flex>
        )}

        {!isLoadingEmployeeAvailableSlots && employeeAvailableSlots ? (
          <Box>
            <TimeSlot title={t('Morning')} slots={morningAvailableSlots} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
            <TimeSlot title={t('Noon')} slots={afternoonAvailableSlots} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
            <TimeSlot title={t('Evening')} slots={eveningAvailableSlots} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
          </Box>
        ) : (
          <Spinner hidden={!isValidDayOfBusiness || !isLoadingEmployeeAvailableSlots} />
        )}
      </FormControl>

      {!employeeAvailableSlots?.length && (
        <Box>
          <Text fontSize="18px" fontWeight="500" color="red.400">
            {t('Employee is either fully booked or not working on that day.')}
          </Text>
        </Box>
      )}

      <Flex justifyContent="end" mt={10} gap={3}>
        <Button variant="action" fontSize="md" fontWeight="500" px="24px" onClick={prevStep}>
          {t('Back')}
        </Button>
        <Button variant="brand" disabled={employeeAvailableSlots ? employeeAvailableSlots.length === 0 : true} onClick={setUpStep3} px="24px">
          {t('Next')}
        </Button>
      </Flex>
    </Box>
  );
}
