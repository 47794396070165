import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  useToast,
} from '@chakra-ui/react';
import { Field, Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { AuthenticationContext } from 'providers/AuthProvider';
import { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdEmail, MdOutlineAdminPanelSettings, MdOutlineRemoveRedEye, MdPerson, MdPhone } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useMutation, useQueryClient } from 'react-query';
import { createUser, updateUserByAdmin } from 'services/api/user';
import { ADMIN, USER } from 'shared/consts';
import { validateConfirmPassword, validateEmail, validatePassword } from 'shared/helpersFunctions';
import { UserType } from 'shared/types/app.types';
import validator from 'validator';

interface IAddUserFormProps {
  handleCloseFunction: () => void;
  userToEditOrDelete?: UserType;
}

interface IUserForm {
  fullName: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
  role: number;
}

const AddUserForm: FunctionComponent<IAddUserFormProps> = ({ handleCloseFunction, userToEditOrDelete }: IAddUserFormProps) => {
  const textColorSecondary = 'gray.400';
  const [show, setShow] = useState(false);
  const { userData } = useContext(AuthenticationContext);
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const toast = useToast();
  const startFullName = userToEditOrDelete ? `${userToEditOrDelete.firstName} ${userToEditOrDelete.lastName}` : '';
  const startEmail = userToEditOrDelete ? userToEditOrDelete.email : '';
  const startPhone = userToEditOrDelete ? userToEditOrDelete.phone : '';

  const { mutate: updateUserMutation, isLoading: isLoadingUserUpdate } = useMutation(updateUserByAdmin, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries('users');
      toast({
        title: t('User Updated Successfully'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      handleCloseFunction();
    },
    onError: () => {
      toast({
        title: t('User Deleted Error'),
        description: t('Ops error occurred try again later'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const { mutate: createUserMutation, isLoading: isLoadingUserCreate } = useMutation(createUser, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries('users');
      toast({
        title: t('User Created Successfully'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      handleCloseFunction();
    },
    onError: (error) => {
      const description =
        error &&
        typeof error === 'object' &&
        'response' in error &&
        error.response &&
        typeof error.response === 'object' &&
        'status' in error.response &&
        error.response.status === 400
          ? t('Phone number already exists')
          : t('Ops error occurred try again later');
      toast({
        title: t('User Creation Error'),
        description: description,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleFormSubmit = (values: IUserForm, actions: FormikHelpers<IUserForm>) => {
    const fullNameArray = values.fullName.trim().split(' ');
    if (userToEditOrDelete) {
      updateUserMutation({
        id: userToEditOrDelete.id,
        firstName: fullNameArray[0],
        lastName: fullNameArray[1] ?? '',
        email: values.email,
        phone: values.phone,
        role: {
          id: values.role,
        },
      });
    } else {
      createUserMutation({
        email: values.email,
        phone: values.phone,
        firstName: fullNameArray[0],
        lastName: fullNameArray[1] ?? '',
        password: values.password,
        business: { id: userData.business.id },
        role: {
          id: values.role,
        },
      });
    }
  };

  const validateIfToDisableButton = (values: IUserForm, errors: FormikErrors<IUserForm>) => {
    return (
      !!Object.keys(errors).length ||
      !values.fullName ||
      !values.email ||
      !values.phone ||
      (userToEditOrDelete &&
        values.fullName === startFullName &&
        values.phone === userToEditOrDelete.phone &&
        values.role === userToEditOrDelete.role.id &&
        values.email === userToEditOrDelete.email)
    );
  };
  const displaySpinner = isLoadingUserCreate || isLoadingUserUpdate;
  return (
    <Box>
      <Formik
        initialValues={{
          fullName: startFullName,
          email: startEmail,
          phone: startPhone,
          password: '',
          confirmPassword: '',
          role: userToEditOrDelete?.role?.id ?? 1,
        }}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, values, errors }) => (
          <Form>
            <Field
              name="fullName"
              validate={(fullName: string) =>
                !fullName || fullName.trim().split(' ').length > 2 || fullName.trim().split(' ').length < 2
                  ? t('Please provide a first name and last name.')
                  : ''
              }
            >
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="10px" isRequired={true} isInvalid={form.errors.fullName && form.touched.fullName}>
                  <FormLabel htmlFor="fullName">{t('Full Name')}</FormLabel>
                  <InputGroup size="md">
                    <Input
                      {...field}
                      id="fullName"
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      type="text"
                      placeholder={t('User Full Name')}
                      fontWeight="500"
                      size="lg"
                    />
                    <InputLeftElement display="flex" alignItems="center" mt="4px">
                      <Icon color={textColorSecondary} as={MdPerson} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.fullName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="phone" validator={(phone: string) => (!validator.isMobilePhone(phone, 'he-IL') ? t('Enter a valid phone') : '')}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="20px" isInvalid={form.errors.phone && form.touched.phone} isRequired={true}>
                  <FormLabel htmlFor="phone">{t('Phone')}</FormLabel>
                  <InputGroup size="md">
                    <Input
                      {...field}
                      id="phone"
                      fontSize="sm"
                      placeholder="0502223333"
                      size="lg"
                      type={'tel'}
                      variant="auth"
                      sx={{ textAlign: 'right' }}
                    />
                    <InputLeftElement display="flex" alignItems="center" mt="4px">
                      <Icon color={textColorSecondary} as={MdPhone} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="email" validate={(value: string) => validateEmail(value, t)}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="20px" isInvalid={form.errors.email && form.touched.email} isRequired={true}>
                  <FormLabel htmlFor="email">{t('Email')}</FormLabel>
                  <InputGroup size="md">
                    <Input {...field} id="email" fontSize="sm" placeholder={t('User Email')} size="lg" type={'text'} variant="auth" />
                    <InputLeftElement display="flex" alignItems="center" mt="4px">
                      <Icon color={textColorSecondary} as={MdEmail} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            {!userToEditOrDelete && (
              <>
                <Field name="password" validate={(value: string) => validatePassword(value, t)}>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl mb="10px" isRequired={true} isInvalid={form.errors.password && form.touched.password}>
                      <FormLabel htmlFor="password">{t('Password')}</FormLabel>

                      <InputGroup size="md">
                        <Input
                          {...field}
                          id="password"
                          variant="auth"
                          fontSize="sm"
                          ms={{ base: '0px', md: '0px' }}
                          type={show ? 'text' : 'password'}
                          placeholder={t('Min. 8 characters')}
                          fontWeight="500"
                          size="lg"
                        />
                        <InputLeftElement display="flex" alignItems="center" mt="4px">
                          <Icon
                            color={textColorSecondary}
                            _hover={{ cursor: 'pointer' }}
                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={() => setShow(!show)}
                          />
                        </InputLeftElement>
                      </InputGroup>
                      <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="confirmPassword" validate={() => validateConfirmPassword(values.password, values.confirmPassword, t)}>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl mb="10px" isRequired={true} isInvalid={form.errors.confirmPassword && form.touched.confirmPassword}>
                      <FormLabel htmlFor="confirmPassword">{t('Confirm Password')}</FormLabel>

                      <InputGroup size="md">
                        <Input
                          {...field}
                          id="confirmPassword"
                          type={show ? 'text' : 'password'}
                          variant="auth"
                          fontSize="sm"
                          ms={{ base: '0px', md: '0px' }}
                          placeholder={t('Min. 8 characters')}
                          fontWeight="500"
                          size="lg"
                        />
                        <InputLeftElement display="flex" alignItems="center" mt="4px">
                          <Icon
                            color={textColorSecondary}
                            _hover={{ cursor: 'pointer' }}
                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={() => setShow(!show)}
                          />
                        </InputLeftElement>
                      </InputGroup>
                      <FormErrorMessage>{form.errors.confirmPassword}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </>
            )}
            <Field name="role">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl mb="20px" isInvalid={form.errors.role && form.touched.role} isRequired={true}>
                  <FormLabel htmlFor="role">{t('Role')}</FormLabel>
                  <InputGroup size="md">
                    <Select {...field} variant="auth" iconColor="transparent">
                      {[ADMIN, USER].map((role, index) => {
                        return (
                          <option key={index} value={index + 1}>
                            {role}
                          </option>
                        );
                      })}
                    </Select>
                    <InputLeftElement display="flex" alignItems="center">
                      <Icon color={textColorSecondary} as={MdOutlineAdminPanelSettings} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.role}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            {/* <Field name="email" validate={validateEmail}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  mb="20px"
                  isInvalid={form.errors.email && form.touched.email}
                  isRequired={true}
                >
                  <FormLabel htmlFor="email">{t("Email")}</FormLabel>
                  <InputGroup size="md">
                    <Input
                      {...field}
                      id="email"
                      fontSize="sm"
                      placeholder={t("User Email")}
                      size="lg"
                      type={"text"}
                      variant="auth"
                    />
                    <InputLeftElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon color={textColorSecondary} as={MdEmail} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field> */}
            {/* <Field name="gender">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  mb="20px"
                  isInvalid={form.errors.gender && form.touched.gender}
                >
                  <FormLabel htmlFor="gender">{t("Gender")}</FormLabel>
                  <InputGroup>
                    <Box w="50%">
                      <Select
                        value={{ ...gender, label: t(gender.label) }}
                        components={SelectCustomComponents}
                        options={GENDER_OPTIONS.map((gender) => {
                          return { ...gender, label: t(gender.label) };
                        })}
                        onChange={(gender) => setGender(gender)}
                        variant="auth"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 2000,
                          }),
                        }}
                      />
                    </Box>
                    <InputLeftElement
                      display="flex"
                      alignItems="center"
                      w="48%"
                      justifyContent={"start"}
                    >
                      <Icon
                        w="20px"
                        h="20px"
                        color={textColorSecondary}
                        as={MdMale}
                      />
                      <Icon
                        w="20px"
                        h="20px"
                        color={textColorSecondary}
                        as={MdFemale}
                      />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.gender}</FormErrorMessage>
                </FormControl>
              )}
            </Field> */}
            {/* <Field name="treatments">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  mb="20px"
                  isInvalid={form.errors.treatments && form.touched.treatments}
                >
                  <FormLabel htmlFor="treatments">
                    {t("Treatments related")}
                  </FormLabel>
                  <InputGroup>
                    <Box w="90%">
                      {!isLoadingTreatments ? (
                        <Select
                          isMulti
                          value={chosenTreatments}
                          components={TreatmentsSelectCustomComponents}
                          options={treatments}
                          variant="auth"
                          placeholder={t("Select treatments")}
                          onChange={(treatments) =>
                            setChosenTreatments([...treatments])
                          }
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (provided) => ({
                              ...provided,
                              zIndex: 2000,
                            }),
                          }}
                        />
                      ) : (
                        <Spinner />
                      )}
                    </Box>
                    <InputLeftElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon color={textColorSecondary} as={MdDesignServices} />
                    </InputLeftElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.treatments}</FormErrorMessage>
                </FormControl>
              )}
            </Field> */}
            {/* {false && (
                    <>
                      <Text
                        color={errorColorBrand}
                        fontSize="sm"
                        mb="12px"
                        fontWeight="500"
                      >
                        Email or Password are invalid
                      </Text>
                    </>
                  )} */}
            <Flex gap="20px" justifyContent={'end'} mt="30px">
              <Button onClick={handleCloseFunction}>{t('Cancel')}</Button>
              <Button
                isLoading={displaySpinner}
                colorScheme="teal"
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                isDisabled={validateIfToDisableButton(values, errors)}
              >
                {!userToEditOrDelete ? t('Create') : t('Update')}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddUserForm;
