import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Avatar, Box, Button, Flex, Icon, Spinner, Table, Tag, TagLabel, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/cards/Card';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBell, FaCalendarWeek, FaClock, FaListAlt, FaMoneyBillWave, FaUser, FaUserTie } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { APPOINTMENT_PARAM, DAYS_OF_WEEK } from 'shared/consts';
import { useNewlyCreatedAppointments } from 'shared/hooks/useNewlyCreatedAppointments';
import { AppointmentEntity } from 'shared/types/app.types';

export default function NewlyCreatedAppointments() {
  const { t } = useTranslation();
  const { userData } = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [page, setPage] = useState<number>(1);

  const { newlyCreatedAppointmentsData, isLoadingNewlyCreatedAppointments } = useNewlyCreatedAppointments({
    businessData: userData.business,
    page,
  });

  const navigateToCalendar = (appointment: AppointmentEntity) => {
    const url = `/admin/calendar?${APPOINTMENT_PARAM}=${encodeURIComponent(appointment.id)}`;
    const appointmentDate = new Date(appointment.dateOfAppointment);
    appointmentDate.setDate(appointmentDate.getDate() + 1);
    const fromDate = appointmentDate.toLocaleDateString();
    const toDateStr = appointmentDate.toLocaleDateString();
    navigate(url, {
      state: {
        ...appointment,
        customerId: appointment.customerId,
        employeeId: appointment.employeeId,
        chosenDates: {
          fromDate,
          toDate: toDateStr,
          day: new Date(appointment.dateOfAppointment).getDay(),
          date: new Date(appointment.dateOfAppointment),
          view: 'timeGridDay',
        },
      },
    });
  };

  return (
    <Card alignItems="center" flexDirection="column" w="100%">
      <Box width="100%">
        <Box
          mb={4}
          display="flex"
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent={{ base: 'center', md: 'space-between' }}
          alignItems={{ base: 'flex-start', md: 'center' }}
          gap={{ base: 3, md: 0 }}
        >
          <Flex flexDirection="column" gap={2} mb={{ base: 2, md: 0 }}>
            <Text color={textColor} fontSize="xl" fontWeight="700" display="flex" alignItems="center" gap={2} lineHeight="100%">
              <Icon as={FaCalendarWeek} mr={1} fontSize={'lg'} />
              {t('Newly Created Appointments')} ({newlyCreatedAppointmentsData.total} {t('appointments')})
            </Text>
            <Text color={'gray.600'} fontSize={{ base: 'sm', md: 'md' }} lineHeight="100%">
              {t('Here you can see the list of appointments created in the last 7 days.')}
            </Text>
          </Flex>
          {/* <Button
            px={10}
            size={'md'}
            onClick={() => setOpenModal(true)}
            rightIcon={<Icon as={FaBirthdayCake} />}
            variant="action"
            width={{ base: '100%', md: 'auto' }}
          >
            {t('Wish to all')}
          </Button> */}
        </Box>
        {isLoadingNewlyCreatedAppointments ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%" minHeight="250px">
            <Spinner size="xl" />
          </Box>
        ) : (
          <Box maxHeight="400px" minHeight="250px" overflowY="auto" overflowX="auto" width="100%">
            <Table variant="simple" size={{ base: 'sm', md: 'md' }}>
              <Thead>
                <Tr>
                  <Th>
                    <Flex alignItems="center" gap={1}>
                      <Icon as={FaClock} />
                      {t('Time')}
                    </Flex>
                  </Th>
                  <Th>
                    <Flex alignItems="center" gap={1}>
                      <Icon as={FaUser} />
                      {t('Customer')}
                    </Flex>
                  </Th>
                  <Th>
                    <Flex alignItems="center" gap={1}>
                      <Icon as={FaListAlt} />
                      {t('Treatments')}
                    </Flex>
                  </Th>
                  <Th>
                    <Flex alignItems="center" gap={1}>
                      <Icon as={FaMoneyBillWave} />
                      {t('Price')}
                    </Flex>
                  </Th>
                  <Th>
                    <Flex alignItems="center" gap={1}>
                      <Icon as={FaUserTie} />
                      {t('Employee')}
                    </Flex>
                  </Th>
                  <Th>
                    <Flex alignItems="center" gap={1}>
                      <Icon as={FaBell} />
                      {t('Action required')}
                    </Flex>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {newlyCreatedAppointmentsData.items.length === 0 ? (
                  <Tr>
                    <Td colSpan={5} textAlign="center">
                      {t('No newly created appointments found.')}
                    </Td>
                  </Tr>
                ) : (
                  newlyCreatedAppointmentsData.items.map((appointment: AppointmentEntity) => (
                    <Tr key={appointment.id} onClick={() => navigateToCalendar(appointment)} cursor="pointer">
                      <Td textAlign="center">
                        <Flex direction="column" alignItems="flex-start">
                          <Text fontSize="sm" color="gray.600">
                            {t(DAYS_OF_WEEK[new Date(appointment.dateOfAppointment).getDay()])}
                          </Text>
                          <Text fontSize="sm" color="gray.600">
                            {new Date(appointment.dateOfAppointment)
                              .toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                              })
                              .replace('/', '/')}
                          </Text>
                          <Text>{appointment.startOfAppointment.substring(0, 5)}</Text>
                        </Flex>
                      </Td>
                      <Td>
                        <Box display="flex" alignItems="center" gap={1} justifyContent="center">
                          {appointment.customerId?.photo && (
                            <Avatar
                              h={{ base: '25px', md: '30px', lg: '35px' }}
                              w={{ base: '25px', md: '30px', lg: '35px' }}
                              src={appointment.customerId?.photo?.path}
                            />
                          )}
                          <Text ml={2}>{`${appointment.customerId.firstName} ${appointment.customerId.lastName}`}</Text>
                        </Box>
                      </Td>
                      <Td>
                        <Flex flexWrap="wrap" gap={0.5}>
                          {appointment.treatments?.map((t) => {
                            return (
                              <Tag
                                key={t.id}
                                px={1}
                                size="sm"
                                borderRadius="full"
                                variant="solid"
                                colorScheme="purple"
                                maxW="100px"
                                overflow="hidden"
                              >
                                <TagLabel
                                  isTruncated
                                  sx={{
                                    display: 'block',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    maxWidth: '80px',
                                  }}
                                >
                                  {t.name}
                                </TagLabel>
                              </Tag>
                            );
                          })}
                        </Flex>
                      </Td>
                      <Td>
                        <Text fontWeight="500">{`${appointment.price}₪`}</Text>
                      </Td>
                      <Td>{`${appointment.employeeId.firstName} ${appointment.employeeId.lastName}`}</Td>
                      <Td>{t('not required')}</Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </Box>
        )}
        <Flex justifyContent={'center'} alignItems="center" py={4} flexWrap={{ base: 'wrap', md: 'nowrap' }} gap={2}>
          <Button
            onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))}
            disabled={page === 1}
            variant="action"
            size={'sm'}
            leftIcon={<ChevronRightIcon />}
          >
            {t('Previous')}
          </Button>
          <Text as="span" mx={4}>
            {page} / {Math.ceil(newlyCreatedAppointmentsData.total / newlyCreatedAppointmentsData.limit)}
          </Text>
          <Button
            onClick={() => setPage((prevPage) => prevPage + 1)}
            disabled={!newlyCreatedAppointmentsData.hasMore}
            variant="brand"
            rightIcon={<ChevronLeftIcon />}
            size={'sm'}
          >
            {t('Next')}
          </Button>
        </Flex>
      </Box>

      {/* <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <ModalOverlay />
        <ModalContent mx={{ base: 4, md: 0 }}>
          <ModalHeader sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Icon as={FaBirthdayCake} mr={2} />
            {t('Wish happy birthday')}
          </ModalHeader>
          <ModalBody sx={{}}>
            {selectedCustomer && (
              <>
                <Text fontSize="md" fontWeight="bold">
                  {`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
                  {` (${getCustomerAge(selectedCustomer.birthDate)} ${t('years old')})`}
                </Text>
                <Text>{t('What is the message?')}</Text>
                <Box>
                  <Select
                    label={t('Choose a message')}
                    value={selectedMessage}
                    onChange={(value) => setSelectedMessage(value)}
                    options={getHappyBirthdayMessages(selectedCustomer.firstName).map((message) => ({ value: message, label: message }))}
                  />
                  <TextField
                    value={`${selectedMessage}\n\n${EASY_BOOK_BOOK_LINK}business/${business.id}/appointments/create/`}
                    onChange={(e) => setSelectedMessage(e.target.value)}
                    fullWidth
                    multiline
                    rows={6}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </>
            )}
          </ModalBody>
          <ModalFooter sx={{ display: 'flex', gap: 2, flexDirection: { base: 'column', sm: 'row' }, width: '100%' }}>
            <Button variant="outline" onClick={() => setOpenModal(false)} rightIcon={<Icon as={FaTimes} />} width={{ base: '100%', sm: 'auto' }}>
              {t('Cancel')}
            </Button>
            <Button
              variant="brand"
              isLoading={isLoadingSendingSms}
              onClick={handleSendMessage}
              rightIcon={<Icon as={FaPaperPlane} />}
              width={{ base: '100%', sm: 'auto' }}
            >
              {t('Send SMS')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
    </Card>
  );
}
