import { useToast } from '@chakra-ui/react';
import { t } from 'i18next';
import { createContext, ReactElement, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { createTreatment, deleteTreatment, updateTreatment } from 'services/api/treatment';
import { CreateTreatmentDTO, UpdateTreatmentDTO } from 'shared/types/app.types';

interface IBusinessContext {
  isLoadingTreatUpdate: boolean;
  isLoadingTreatCreate: boolean;
  isLoadingTreatDelete: boolean;
  setChosenDateAndRouteToCalendar: (newDate: Date) => void;
  deleteTreatmentMutation: ({ treatId }: { treatId: string }) => void;
  createTreatmentMutation: (treatmentForm: CreateTreatmentDTO) => void;
  updateTreatmentMutation: (treatmentUpdateData: { updatedTreatment: UpdateTreatmentDTO; treatId: string }) => void;
}

const BusinessContext = createContext<IBusinessContext>({
  isLoadingTreatUpdate: false,
  isLoadingTreatDelete: false,
  isLoadingTreatCreate: false,
  setChosenDateAndRouteToCalendar: () => null,
  deleteTreatmentMutation: () => null,
  createTreatmentMutation: () => null,
  updateTreatmentMutation: () => null,
});

function BusinessProvider({ children }: { children: ReactElement }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutate: createTreatmentMutation, isLoading: isLoadingTreatCreate } = useMutation(createTreatment, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries('employees');
      toast({
        title: t('Treatment Created Successfully'),
        description: t('successfully created treatment'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      await queryClient.invalidateQueries('treatments');
    },
  });

  const { mutate: updateTreatmentMutation, isLoading: isLoadingTreatUpdate } = useMutation(updateTreatment, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries('treatments');
      toast({
        title: t('Treatment Updated Success'),
        description: t('successfully updated treatment'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const { mutate: deleteTreatmentMutation, isLoading: isLoadingTreatDelete } = useMutation(deleteTreatment, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries('treatments');
      toast({
        title: t('Treatment Deleted Success'),
        description: t('successfully deleted treatment, be aware that this treatment might have appointments related to it.'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t('Treatment Deleted Error'),
        description: t('This treatment is associated with existing appointments so it can not be deleted'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const setChosenDateAndRouteToCalendar = (chosenDate: Date) => {
    const toDate = new Date(chosenDate);
    toDate.setDate(toDate.getDate() + 1);
    const fromDate = chosenDate.toLocaleDateString();
    const toDateStr = toDate.toLocaleDateString();
    const randomQueryParam = Math.random().toString(36).substring(2);

    navigate(`/admin/calendar?refresh=${randomQueryParam}`, {
      replace: true,
      state: {
        chosenDates: {
          fromDate,
          toDate: toDateStr,
          day: chosenDate.getDay(),
          date: chosenDate,
          view: 'timeGridDay',
        },
      },
    });
  };

  return (
    <BusinessContext.Provider
      value={useMemo(
        () => ({
          createTreatmentMutation,
          deleteTreatmentMutation,
          updateTreatmentMutation,
          setChosenDateAndRouteToCalendar,
          isLoadingTreatUpdate,
          isLoadingTreatDelete,
          isLoadingTreatCreate,
        }),
        [createTreatmentMutation, deleteTreatmentMutation, updateTreatmentMutation, isLoadingTreatUpdate, isLoadingTreatDelete, isLoadingTreatCreate],
      )}
    >
      {children}
    </BusinessContext.Provider>
  );
}

export { BusinessContext, BusinessProvider };
