import {
  Box,
  Button,
  Flex,
  FormControl,
  Icon,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select as SingleSelect,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { EventChangeArg } from '@fullcalendar/core';
import { cloneDeep } from 'lodash';
import { AuthenticationContext } from 'providers/AuthProvider';
import { CalendarContext } from 'providers/CalendarProvider';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsScissors } from 'react-icons/bs';
import { IoMdClock } from 'react-icons/io';
import { MdArrowForward, MdClose } from 'react-icons/md';
import { DAYS_OF_WEEK } from 'shared/consts';
import { addMinutesToTime, displayTimeFromISOToString } from 'shared/helpersFunctions';
import { AppointmentEntity, UpdateAppointmentDto } from 'shared/types/app.types';

interface IEditAppointmentModalProps {
  isOpen: boolean;
  closeEditModal: () => void;
  filteredTimesByBusinessHours: string[];
  minOpenTime: string;
  closeEditModalAndRevertChanges: () => void;
  editDatesData: EventChangeArg;
}

export default function EditAppointmentModal({
  isOpen,
  closeEditModal,
  closeEditModalAndRevertChanges,
  filteredTimesByBusinessHours,
  minOpenTime,
  editDatesData,
}: IEditAppointmentModalProps) {
  const bgColor = useColorModeValue('brand.100', 'brand.400');
  const borderColor = useColorModeValue('brand.600', 'brand.100');
  const { userData } = useContext(AuthenticationContext);
  const [selectedTime, setSelectedTime] = useState<string>(
    displayTimeFromISOToString(editDatesData.event._instance.range.start) > minOpenTime
      ? displayTimeFromISOToString(editDatesData.event._instance.range.start)
      : minOpenTime,
  );
  const errorColorBrand = useColorModeValue('red.500', 'red.300');
  const initialRef = useRef();
  const finalRef = useRef();
  const { t } = useTranslation();

  const { updateAppointmentMutation, updateAppointmentError } = useContext(CalendarContext);

  const updateAppointment = () => {
    const appointmentId = editDatesData.oldEvent._def.publicId;
    const oldAppointmentEntity: AppointmentEntity = cloneDeep(editDatesData.event._def.extendedProps) as AppointmentEntity;
    const newDate = editDatesData.event._instance.range.start.toISOString().split('T')[0];
    const appointmentUpdateData: UpdateAppointmentDto = {
      ...oldAppointmentEntity,
    } as unknown as UpdateAppointmentDto;
    const endOfAppointment = addMinutesToTime(
      selectedTime,
      oldAppointmentEntity.treatments.map((treat) => +treat.timeOfTreatment).reduce((acc, newTime) => acc + newTime),
    );
    appointmentUpdateData.endOfAppointment = endOfAppointment;
    appointmentUpdateData.employeeId = oldAppointmentEntity.employeeId.id;
    appointmentUpdateData.customerId = oldAppointmentEntity.customerId?.id;
    appointmentUpdateData.startOfAppointment = selectedTime;
    appointmentUpdateData.dateOfAppointment = newDate;
    appointmentUpdateData.businessId = userData.business.id;
    updateAppointmentMutation({
      updatedAppointment: appointmentUpdateData,
      appointmentId,
      onClose: closeEditModal,
    });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      size="lg"
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={closeEditModalAndRevertChanges}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex gap="5px" alignItems={'center'}>
            <Button ml={3} onClick={closeEditModalAndRevertChanges}>
              <Icon as={MdClose} width="20px" height="20px" color="inherit" />
            </Button>
            <Icon as={BsScissors} width="30px" height="30px" color="brand.500" mx="7px" />
            {t('Change appointment date?')}
          </Flex>
        </ModalHeader>
        <ModalBody pb={6}>
          <Box>
            {t('Are you sure you want to change to appointment date?')}
            <Flex width="100%" p={5} justifyContent={'space-evenly'} alignItems="center">
              <Box
                textAlign={'center'}
                p="20px"
                display={'flex'}
                flexDirection="column"
                borderRadius="8px"
                gap={2}
                bg={bgColor}
                borderColor={borderColor}
                border={`1px solid`}
              >
                <Text fontWeight="800">{`${t('Day')} ${t(DAYS_OF_WEEK[editDatesData.event._instance.range.start.getDay()])}`}</Text>
                <Text fontWeight="500">{editDatesData.event._instance.range.start.toLocaleDateString('en-GB')}</Text>
                <FormControl isRequired={true}>
                  <InputGroup size="md">
                    <SingleSelect
                      onChange={(val) => setSelectedTime(val.target.value)}
                      variant="auth"
                      iconColor="transparent"
                      value={selectedTime}
                      sx={{ borderColor: borderColor }}
                    >
                      {filteredTimesByBusinessHours.map((time, index) => {
                        return (
                          <option key={index} value={time}>
                            {time}
                          </option>
                        );
                      })}
                    </SingleSelect>
                    <InputLeftElement display="flex" alignItems="center">
                      <Icon as={IoMdClock} />
                    </InputLeftElement>
                  </InputGroup>
                </FormControl>
              </Box>
              <Box>
                <Icon as={MdArrowForward} width="30px" height="30px" color="inherit" />
              </Box>
              <Box
                textAlign={'center'}
                p="20px"
                display={'flex'}
                flexDirection="column"
                gap={2}
                borderRadius="8px"
                bg={bgColor}
                borderColor={borderColor}
                border={`1px solid`}
              >
                <Text fontWeight="800">{`${t('Day')} ${t(DAYS_OF_WEEK[editDatesData.oldEvent._instance.range.start.getDay()])}`}</Text>
                <Text fontWeight="500">{editDatesData.oldEvent._instance.range.start.toLocaleDateString('en-GB')}</Text>
                <Text fontWeight="500">{displayTimeFromISOToString(editDatesData.oldEvent._instance.range.start)}</Text>
              </Box>
            </Flex>
            <Box>
              {updateAppointmentError && (
                <>
                  <Text color={errorColorBrand} fontSize="sm" mb="12px" fontWeight="500">
                    {t('An Error occurred, maybe the employee is already busy? Or maybe Business is closed that day?')}
                  </Text>
                </>
              )}
            </Box>
            <Flex pt="10px" gap="5px" justifyContent={'end'}>
              <Button variant={'action'} fontSize="md" px="24px" onClick={closeEditModalAndRevertChanges} py="5px">
                {t('Cancel')}
              </Button>
              <Button variant="brand" fontSize="md" onClick={updateAppointment} px="24px" py="5px">
                {t('Change')}
              </Button>
            </Flex>
          </Box>
          {/* <EmployeeForm
          handleCloseFunction={closeModal}
          employeeToEditOrDelete={employeeToEditOrDelete}
        /> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
