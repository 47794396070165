import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { TextField } from '@mui/material';
import Card from 'components/cards/Card';
import Select from 'components/inputs/Select';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBirthdayCake, FaCheckCircle, FaPaperPlane, FaSms, FaTimes, FaUser } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';
import { sendSmsToCustomer } from 'services/api/business';
import { BASIC_DATA_QUERY_KEY, EASY_BOOK_BOOK_LINK, UPCOMING_BIRTHDAY_CUSTOMERS_QUERY_KEY } from 'shared/consts';
import { useUpcomingBirthdayCustomers } from 'shared/hooks/useUpcomingBirthdayCustomers';
import { CustomerEntity } from 'shared/types/app.types';

export default function UpcomingBirthdays() {
  const { t } = useTranslation();
  const { userData } = useContext(AuthenticationContext);
  const { business } = userData;
  const toast = useToast();
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerEntity | null>(null);
  const [selectedMessage, setSelectedMessage] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [page, setPage] = useState<number>(1);
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { mutate: sendSmsToCustomerMutation, isLoading: isLoadingSendingSms } = useMutation(sendSmsToCustomer, {
    onSuccess: async () => {
      toast({
        title: t('Appointment updated successfully'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      await queryClient.invalidateQueries(BASIC_DATA_QUERY_KEY);
      await queryClient.invalidateQueries(UPCOMING_BIRTHDAY_CUSTOMERS_QUERY_KEY);
      setOpenModal(false);
    },
    onError: () => {
      toast({
        title: t('Failed to send SMS'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const { customersData, isSuccessCustomers, isLoadingCustomers } = useUpcomingBirthdayCustomers({
    businessData: userData.business,
    page,
    searchTerm,
  });
  const twoHundredDaysInMilliseconds = 200 * 24 * 60 * 60 * 1000;

  // Define base messages without customer data
  const getHappyBirthdayMessages = (customerName: string) => [
    `${customerName}! 😊 ${t('🎉 Happy Birthday from')} ${business.name}! ${t('We hope your day is as awesome as you are! 🎂')}`,
    `${customerName}! 🎁 ${t('Happy Birthday from')} ${business.name}! ${t('Enjoy a special 10% discount on your next visit as our birthday gift to you! 🎉')}`,
    `${customerName}! 🥳 ${t('Hey! 🎈')} ${t("It's your special day! 🎁")} ${t('Cheers to another year of greatness from')} ${business.name}!`,
    `${customerName}! 😁 ${t('Woohoo! 🎊')} ${t('Happy Birthday! 🎂')} ${t('Sending you lots of love and kisses from')} ${business.name}! 🍰`,
  ];

  const finalMessage = `${selectedMessage}\n\n${business.businessShortUrl || `${selectedMessage}\n\n${EASY_BOOK_BOOK_LINK}business/${business.id}/appointments/create/`}`;

  const handleSendMessage = () => {
    // Implement SMS sending logic here
    sendSmsToCustomerMutation({
      businessId: business.id,
      smsCustomerDataFromForm: {
        customerId: selectedCustomer?.id,
        message: finalMessage,
      },
    });
  };

  const handleSendBirthdayWish = (customer: CustomerEntity) => {
    setSelectedCustomer(customer);
    const messages = getHappyBirthdayMessages(customer.firstName);
    setSelectedMessage(messages[0]);
    setOpenModal(true);
  };

  const getCustomerAge = (birthDate: string) => {
    return new Date().getFullYear() - new Date(birthDate).getFullYear();
  };

  return (
    <Card alignItems="center" flexDirection="column" w="100%">
      <Box width="100%">
        <Box
          mb={4}
          display="flex"
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent={{ base: 'center', md: 'space-between' }}
          alignItems={{ base: 'flex-start', md: 'center' }}
          gap={{ base: 3, md: 0 }}
        >
          <Flex flexDirection="column" gap={2} mb={{ base: 2, md: 0 }}>
            <Text color={textColor} fontSize="xl" fontWeight="700" display="flex" alignItems="center" gap={2} lineHeight="100%">
              <Icon as={FaBirthdayCake} mr={1} fontSize={'lg'} />
              {t('Upcoming Birthdays')} ({customersData.total} {t('customers')})
            </Text>
            <Text color={'gray.600'} fontSize={{ base: 'sm', md: 'md' }} lineHeight="100%">
              {t('Here you can see the list of customers with upcoming birthdays and send them a birthday wish.')}
            </Text>
          </Flex>
          {/* <Button
            px={10}
            size={'md'}
            onClick={() => setOpenModal(true)}
            rightIcon={<Icon as={FaBirthdayCake} />}
            variant="action"
            width={{ base: '100%', md: 'auto' }}
          >
            {t('Wish to all')}
          </Button> */}
        </Box>
        {isLoadingCustomers ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%" minHeight="250px">
            <Spinner size="xl" />
          </Box>
        ) : (
          <Box maxHeight="350px" minHeight="250px" overflowY="auto" overflowX="auto" width="100%">
            <Table variant="simple" size={{ base: 'sm', md: 'md' }}>
              <Thead>
                <Tr>
                  <Th>
                    <Flex alignItems="center" gap={1}>
                      <Icon as={FaUser} />
                      {t('Customer')}
                    </Flex>
                  </Th>
                  <Th>
                    <Flex alignItems="center" gap={1}>
                      <Icon as={FaBirthdayCake} />
                      {t('Birthday')}
                    </Flex>
                  </Th>
                  <Th>
                    <Flex alignItems="center" gap={1}>
                      <Icon as={FaUser} />
                      {t('Age')}
                    </Flex>
                  </Th>
                  <Th>
                    <Flex alignItems="center" gap={1}>
                      <Icon as={FaSms} />
                      {t('Send SMS')}
                    </Flex>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {customersData.items.length === 0 ? (
                  <Tr>
                    <Td colSpan={5} textAlign="center">
                      {t('No upcoming birthdays found.')}
                    </Td>
                  </Tr>
                ) : (
                  customersData.items.map((customer) => (
                    <Tr key={customer.id}>
                      <Td>
                        <Box display="flex" alignItems="center" gap={1} justifyContent="center">
                          {customer.photo && (
                            <Avatar
                              h={{ base: '25px', md: '30px', lg: '35px' }}
                              w={{ base: '25px', md: '30px', lg: '35px' }}
                              src={customer.photo.path}
                            />
                          )}
                          <Text ml={2}>{`${customer.firstName} ${customer.lastName}`}</Text>
                        </Box>
                      </Td>
                      <Td>{new Date(customer.birthDate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}</Td>
                      <Td>{getCustomerAge(customer.birthDate)}</Td>
                      <Td>
                        {!customer.lastBirthdaySmsSentAt ||
                        new Date().getTime() - new Date(customer.lastBirthdaySmsSentAt).getTime() > twoHundredDaysInMilliseconds ? (
                          <Button
                            rightIcon={<Icon as={FaBirthdayCake} />}
                            onClick={() => handleSendBirthdayWish(customer)}
                            variant={'brand'}
                            size={'xs'}
                          >
                            {t('Send SMS')}
                          </Button>
                        ) : (
                          <Flex fontSize={'xs'} color={'gray.500'} gap={1} alignItems="center">
                            <Icon as={FaCheckCircle} mr={1} fontSize={'md'} color="green.500" />
                            {t('Sent')}{' '}
                            {Math.floor((new Date().getTime() - new Date(customer.lastBirthdaySmsSentAt).getTime()) / (1000 * 60 * 60 * 24))}{' '}
                            {t('days')}
                          </Flex>
                        )}
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </Box>
        )}
        <Flex justifyContent={'center'} alignItems="center" py={4} flexWrap={{ base: 'wrap', md: 'nowrap' }} gap={2}>
          <Button
            onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))}
            disabled={page === 1}
            variant="action"
            size={'sm'}
            leftIcon={<ChevronRightIcon />}
          >
            {t('Previous')}
          </Button>
          <Text as="span" mx={4}>
            {page} / {Math.ceil(customersData.total / customersData.limit)}
          </Text>
          <Button
            onClick={() => setPage((prevPage) => prevPage + 1)}
            disabled={!customersData.hasMore}
            variant="brand"
            rightIcon={<ChevronLeftIcon />}
            size={'sm'}
          >
            {t('Next')}
          </Button>
        </Flex>
      </Box>

      <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <ModalOverlay />
        <ModalContent mx={{ base: 4, md: 0 }}>
          <ModalHeader sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Icon as={FaBirthdayCake} mr={2} />
            {t('Wish happy birthday')}
          </ModalHeader>
          <ModalBody sx={{}}>
            {selectedCustomer && (
              <>
                <Text fontSize="md" fontWeight="bold">
                  {`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
                  {` (${getCustomerAge(selectedCustomer.birthDate)} ${t('years old')})`}
                </Text>
                <Text>{t('What is the message?')}</Text>
                <Box>
                  <Select
                    label={t('Choose a message')}
                    value={selectedMessage}
                    onChange={(value) => setSelectedMessage(value)}
                    options={getHappyBirthdayMessages(selectedCustomer.firstName).map((message) => ({ value: message, label: message }))}
                  />
                  <TextField
                    value={finalMessage}
                    onChange={(e) => setSelectedMessage(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </>
            )}
          </ModalBody>
          <ModalFooter sx={{ display: 'flex', gap: 2, flexDirection: { base: 'column', sm: 'row' }, width: '100%' }}>
            <Button variant="outline" onClick={() => setOpenModal(false)} rightIcon={<Icon as={FaTimes} />} width={{ base: '100%', sm: 'auto' }}>
              {t('Cancel')}
            </Button>
            <Button
              variant="brand"
              isLoading={isLoadingSendingSms}
              onClick={handleSendMessage}
              rightIcon={<Icon as={FaPaperPlane} />}
              width={{ base: '100%', sm: 'auto' }}
            >
              {t('Send SMS')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
