import { useToast } from '@chakra-ui/react';
import { createContext, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { createAppointment, deleteAppointment, updateAppointment } from 'services/api/appointments';
import { AppointmentDeletionReasonEnum } from 'shared/enums';
import { CreateAppointmentPayload, UpdateAppointmentDto } from 'shared/types/app.types';

interface ICalendarContext {
  updateAppointmentMutation: ({
    updatedAppointment,
    appointmentId,
    onClose,
  }: {
    updatedAppointment: UpdateAppointmentDto;
    appointmentId: string;
    onClose: () => void;
  }) => void;
  createAppointmentMutation: (payload: CreateAppointmentPayload) => void;
  deleteAppointmentMutation: ({
    appointmentId,
    deletionReason,
  }: {
    appointmentId: string;
    deletionReason: AppointmentDeletionReasonEnum;
    onClose: () => void;
  }) => void;
  isLoadingAppointmentUpdate: boolean;
  isSuccessAppointmentCreation: boolean;
  isErrorAppointmentCreation: boolean;
  isLoadingAppointmentCreation: boolean;
  isSuccessAppointmentDeletion: boolean;
  isErrorAppointmentDeletion: boolean;
  isLoadingAppointmentDeletion: boolean;
  createAppointmentError: any | null;
  updateAppointmentError: any | null;
  deleteAppointmentError: any | null;
  isErrorAppointmentUpdate: boolean;
  isSuccessAppointmentUpdate: boolean;
}

const CalendarContext = createContext<ICalendarContext>({
  updateAppointmentMutation: () => null,
  createAppointmentMutation: () => null,
  deleteAppointmentMutation: () => null,
  isLoadingAppointmentUpdate: false,
  deleteAppointmentError: false,
  isLoadingAppointmentCreation: false,
  isSuccessAppointmentCreation: false,
  isErrorAppointmentCreation: false,
  isLoadingAppointmentDeletion: false,
  isSuccessAppointmentDeletion: false,
  isErrorAppointmentDeletion: false,
  createAppointmentError: null,
  updateAppointmentError: null,
  isErrorAppointmentUpdate: false,
  isSuccessAppointmentUpdate: false,
});

function CalendarProvider({ children }: { children: ReactElement }) {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();

  const {
    mutate: updateAppointmentMutation,
    error: updateAppointmentError,
    isError: isErrorAppointmentUpdate,
    isSuccess: isSuccessAppointmentUpdate,
    isLoading: isLoadingAppointmentUpdate,
  } = useMutation(updateAppointment, {
    onSuccess: async (response, dataPackage) => {
      await queryClient.invalidateQueries('calendar');
      toast({
        title: t('Appointment Updated Successfully'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      dataPackage.onClose();
    },
    onError: async (errors, dataPackage) => {
      toast({
        title: t('Ops error occurred try again later'),
        description: 'failed to update appointment',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const {
    mutate: createAppointmentMutation,
    error: createAppointmentError,
    isError: isErrorAppointmentCreation,
    isSuccess: isSuccessAppointmentCreation,
    isLoading: isLoadingAppointmentCreation,
  } = useMutation(createAppointment, {
    onSuccess: async (response, dataPackage) => {
      setTimeout(() => {
        dataPackage.closeModal();
      }, 800);
      await queryClient.invalidateQueries('calendar');
      toast({
        title: t('Appointment Created Successfully'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError: async (errors) => {
      toast({
        title: t('Ops error occurred try again later'),
        description: t('failed to create appointment'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const {
    mutate: deleteAppointmentMutation,
    error: deleteAppointmentError,
    isError: isErrorAppointmentDeletion,
    isSuccess: isSuccessAppointmentDeletion,
    isLoading: isLoadingAppointmentDeletion,
  } = useMutation(deleteAppointment, {
    onSuccess: async (response, dataPackage) => {
      setTimeout(() => {
        dataPackage.onClose();
      }, 500);
      toast({
        title: t('Appointment Deleted Successfully'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError: async (errors, dataPackage) => {
      toast({
        title: t('Appointment Deletion failure'),
        description: t('failed to delete appointment'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  return (
    <CalendarContext.Provider
      value={useMemo(
        () => ({
          updateAppointmentMutation,
          createAppointmentMutation,
          deleteAppointmentMutation,
          createAppointmentError,
          deleteAppointmentError,
          isLoadingAppointmentUpdate,
          isLoadingAppointmentCreation,
          isSuccessAppointmentCreation,
          isErrorAppointmentCreation,
          isLoadingAppointmentDeletion,
          isSuccessAppointmentDeletion,
          isErrorAppointmentDeletion,
          updateAppointmentError,
          isErrorAppointmentUpdate,
          isSuccessAppointmentUpdate,
        }),
        [
          updateAppointmentMutation,
          createAppointmentMutation,
          deleteAppointmentMutation,
          deleteAppointmentError,
          createAppointmentError,
          isLoadingAppointmentUpdate,
          isSuccessAppointmentCreation,
          isErrorAppointmentCreation,
          isLoadingAppointmentDeletion,
          isSuccessAppointmentDeletion,
          isErrorAppointmentDeletion,
          updateAppointmentError,
          isErrorAppointmentUpdate,
          isSuccessAppointmentUpdate,
        ],
      )}
    >
      {children}
    </CalendarContext.Provider>
  );
}

export { CalendarContext, CalendarProvider };
