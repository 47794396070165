// Chakra imports
import { Box, Flex, Icon, SimpleGrid, Spinner, useColorModeValue } from '@chakra-ui/react';
import { faMoneyBill1Wave, faSackDollar, faSms } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Assets
// Custom components
import MiniStatistics from 'components/cards/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPeople } from 'react-icons/bs';
import { IoMdClock } from 'react-icons/io';
import { MdDoneOutline } from 'react-icons/md';
import { useQuery } from 'react-query';
import { getDashboardBasicStats, getDashboardTreatmentsRevenue } from 'services/api/business';
import { ACTIVE, ADMIN, BASIC_DATA_QUERY_KEY } from 'shared/consts';
import { getPastMonths } from 'shared/helpersFunctions';
import { DashboardData } from 'shared/types/app.types';
import TreatmentsRevenue from 'views/admin/dashboard/components/WeeklyRevenue';
import NewlyCreatedAppointments from './components/NewlyCreatedAppointments';
import UpcomingBirthdays from './components/UpcomingBirthdays';

export default function HomePage() {
  const brandColor = useColorModeValue('brand.100', 'white');
  const { t } = useTranslation();
  const { userData } = useContext(AuthenticationContext);
  const { data: dashboardBasicData } = useQuery<DashboardData>([BASIC_DATA_QUERY_KEY], () => getDashboardBasicStats(userData.business.id), {
    refetchOnMount: 'always',
    enabled: userData.user.role.name === ADMIN,
  });

  const { data: treatmentsRevenueData, isLoading: isLoadingTreatmentsData } = useQuery<any>(
    ['dashboard-treatments-revenue'],
    () => getDashboardTreatmentsRevenue(userData.business.id, getPastMonths()),
    {
      enabled: userData.user.status.name === ACTIVE && userData.user.role.name === ADMIN,
      refetchOnMount: 'always',
    },
  );

  if (!dashboardBasicData) {
    return (
      <Flex w="80vw" h="100vh" alignItems="center" justifyContent={'center'}>
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box pt={{ base: '120px', md: '100px', xl: '100px' }} px={{ base: '5px', md: '10px' }}>
      <SimpleGrid columns={{ base: 2, md: 3, lg: 3, '2xl': 6 }} gap="10px" mb="20px">
        <MiniStatistics
          startContent={
            <IconBox
              w={{ base: '40px', md: '48px', xl: '56px' }}
              h={{ base: '40px', md: '48px', xl: '56px' }}
              bg="linear-gradient(90deg, #8874bf 0%, #7551FF 100%)"
              color={brandColor}
              icon={<FontAwesomeIcon size="lg" icon={faMoneyBill1Wave} />}
            />
          }
          name={t('Total revenue for this year')}
          value={`₪${dashboardBasicData.totalRevenue}`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w={{ base: '40px', md: '48px', xl: '56px' }}
              h={{ base: '40px', md: '48px', xl: '56px' }}
              bg="linear-gradient(90deg, #8874bf 0%, #7551FF 100%)"
              color={brandColor}
              icon={<FontAwesomeIcon size="lg" icon={faSackDollar} />}
            />
          }
          name={t('Revenue This Month')}
          value={`₪${dashboardBasicData.monthlyRevenue}`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w={{ base: '40px', md: '48px', xl: '56px' }}
              h={{ base: '40px', md: '48px', xl: '56px' }}
              bg="linear-gradient(90deg, #8874bf 0%, #7551FF 100%)"
              color={brandColor}
              icon={<FontAwesomeIcon size="lg" icon={faSms} />}
            />
          }
          name={t('SMS month remainder')}
          value={`${dashboardBasicData.smsQuantityRemainder}`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w={{ base: '40px', md: '48px', xl: '56px' }}
              h={{ base: '40px', md: '48px', xl: '56px' }}
              color={brandColor}
              bg="linear-gradient(90deg, #8874bf 0%, #7551FF 100%)"
              icon={<Icon w={{ base: '24px', md: '28px', xl: '32px' }} h={{ base: '24px', md: '28px', xl: '32px' }} as={BsPeople} />}
            />
          }
          name={t('Total Customers')}
          value={`${dashboardBasicData.totalCustomers}`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w={{ base: '40px', md: '48px', xl: '56px' }}
              h={{ base: '40px', md: '48px', xl: '56px' }}
              bg="linear-gradient(90deg, #8874bf 0%, #7551FF 100%)"
              icon={<Icon w={{ base: '20px', md: '24px', xl: '28px' }} h={{ base: '20px', md: '24px', xl: '28px' }} as={IoMdClock} color="white" />}
            />
          }
          name={t('Future Appointments')}
          value={`${dashboardBasicData.futureAppointments}`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w={{ base: '40px', md: '48px', xl: '56px' }}
              h={{ base: '40px', md: '48px', xl: '56px' }}
              bg="linear-gradient(90deg, #8874bf 0%, #7551FF 100%)"
              icon={
                <Icon
                  w={{ base: '24px', md: '28px', xl: '32px' }}
                  h={{ base: '24px', md: '28px', xl: '32px' }}
                  as={MdDoneOutline}
                  color={brandColor}
                />
              }
            />
          }
          name={t('Total Appointments')}
          value={`${dashboardBasicData.totalAppointments}`}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 2, '2xl': 2 }} gap="20px" mb="20px">
        {/* <AppointmentsPerMonth businessId={userData.business.id} /> */}
        <UpcomingBirthdays />
        <NewlyCreatedAppointments />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        {treatmentsRevenueData && !isLoadingTreatmentsData && <TreatmentsRevenue treatmentsRevenueData={treatmentsRevenueData} displayOnlyBarChart />}
        {isLoadingTreatmentsData && (
          <Box>
            <Spinner />
          </Box>
        )}
        {/* <PieCardTreatment businessId={userData.business.id} title={t('Appointments Per Treatment')} />
        <PieCardEmployee businessId={userData.business.id} title={t('Appointments Per Employee')} />
        <PieCardEmployeeAbsences businessId={userData.business.id} title={t('Absences Per Employee')} /> */}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        {/* <ComplexTable tableData={tableDataComplex} />
        <CheckTable tableData={tableDataCheck} /> */}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        {/* <Tasks /> */}
        {/* <DailyTraffic /> */}
      </SimpleGrid>
    </Box>
  );
}
