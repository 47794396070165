import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import DefaultAuth from 'layouts/auth/Default';
// Assets
import illustration from 'assets/img/auth/auth.png';
import { Field, Form, Formik } from 'formik';
import { AuthenticationContext } from 'providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { MdEmail, MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { validateEmail, validatePassword } from 'shared/helpersFunctions';

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const { t } = useTranslation();
  // create email and password hook vars
  const textColorSecondary = 'gray.800';
  const iconsSecondary = 'brand.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const errorColorBrand = useColorModeValue('red.500', 'red.300');
  const { loginMutation, isLoginError } = useContext(AuthenticationContext);

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="center"
        textAlign={'center'}
        justifyContent="center"
        mb={{ base: '15px', md: '20px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '4vh' }}
        flexDirection="column"
      >
        <Box mb="15px">
          <Heading color={textColor} fontSize="36px" mb="10px">
            {t('Sign In')}
          </Heading>
          <Text color={textColorSecondary} fontWeight="400" fontSize="md">
            {t('Enter your email and password to sign in!')}
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          {/*   <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            {t("Sign in with Google")}
          </Button>
          <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
          >
            <Icon as={BsFacebook} w="20px" h="20px" me="10px" />
            {t("Sign in with Facebook")}
          </Button>
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              {t("or")}
            </Text>
            <HSeparator />
          </Flex> */}

          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={(values, actions) => {
              loginMutation({ email: values.email, password: values.password });
              setTimeout(() => {
                actions.setSubmitting(false);
              }, 1000);
            }}
          >
            {(props) => (
              <Form>
                <Field name="email" validate={(value: string) => validateEmail(value, t)}>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl mb="10px" isRequired={true} isInvalid={form.errors.email && form.touched.email}>
                      <FormLabel htmlFor="email">{t('Email')}</FormLabel>

                      <InputGroup size="md">
                        <Input
                          {...field}
                          id="email"
                          variant="auth"
                          fontSize="sm"
                          ms={{ base: '0px', md: '0px' }}
                          type="email"
                          placeholder="mail@simmmple.com"
                          fontWeight="500"
                          size="lg"
                        />
                        <InputLeftElement display="flex" alignItems="center" mt="4px">
                          <Icon color={iconsSecondary} h="20px" w="20px" as={MdEmail} />
                        </InputLeftElement>
                      </InputGroup>
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password" validate={(value: string) => validatePassword(value, t)}>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl mb="20px" isInvalid={form.errors.password && form.touched.password} isRequired={true}>
                      <FormLabel htmlFor="password">{t('Password')}</FormLabel>
                      <InputGroup size="md">
                        <Input
                          {...field}
                          id="password"
                          fontSize="sm"
                          placeholder={t('Min. 8 characters')}
                          size="lg"
                          type={show ? 'text' : 'password'}
                          variant="auth"
                        />
                        <InputLeftElement display="flex" alignItems="center" mt="4px">
                          <Icon
                            color={iconsSecondary}
                            _hover={{ cursor: 'pointer' }}
                            h="25px"
                            w="25px"
                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={handleClick}
                          />
                        </InputLeftElement>
                      </InputGroup>
                      <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {isLoginError && (
                  <>
                    <Text color={errorColorBrand} fontSize="sm" mb="12px" fontWeight="500">
                      {t('Email or Password are invalid')}
                    </Text>
                  </>
                )}
                <Button
                  isLoading={props.isSubmitting}
                  isDisabled={!!Object.keys(props.errors).length}
                  colorScheme="teal"
                  type="submit"
                  fontSize="md"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                >
                  {t('Sign In')}
                </Button>
              </Form>
            )}
          </Formik>
          <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%" gap={2} mt="0px">
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              {t('Not registered yet?')}
              <NavLink to="/auth/sign-up">
                <Text px={2} color={textColorBrand} as="span" ms="5px" fontWeight="500">
                  {t('Create an Account')}
                </Text>
              </NavLink>
            </Text>
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              {t('Forgot your password?')}

              <NavLink to="/auth/reset-password">
                <Text px={2} color={textColorBrand} as="span" ms="5px" fontWeight="500">
                  {t('Reset Password')}
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
